// import { axiosPrivate } from "../api/axios";
// import { useEffect } from "react";
// import useRefreshToken from "./useRefreshToken";
// import useAuth from "./useAuth";
// import { AxiosRequestConfig, AxiosResponse } from 'axios';

// const useAxiosPrivate = () => {
//   const refresh = useRefreshToken();
//   const { auth } = useAuth();

//   useEffect(() => {

//     const requestIntercept = axiosPrivate.interceptors.request.use(
//         (config: AxiosRequestConfig): any  => {
//           const newConfig = {
//             ...config,
//             headers: {
//               ...config.headers,
//               Authorization: `Bearer ${auth?.accessToken}`
//             }
//           };
//           return newConfig;
//         }, (error: any) => Promise.reject(error) 
//       );

//     const responseIntercept = axiosPrivate.interceptors.response.use(
//       (response: AxiosResponse) => response,
//       async (error: any) => { 
//         const prevRequest = error?.config;
//         if (error?.response?.status === 403 && !prevRequest?.sent) {
//           prevRequest.sent = true;
//           const newAccessToken = await refresh();
//           prevRequest.headers['Authorization'] = `Bearer ${newAccessToken}`;
//           return axiosPrivate(prevRequest);
//         }
//         return Promise.reject(error);
//       }
//     );

//     return () => {
//       axiosPrivate.interceptors.request.eject(requestIntercept);
//       axiosPrivate.interceptors.response.eject(responseIntercept);
//     }
//   }, [auth, refresh])

//   return axiosPrivate;
// }

// export default useAxiosPrivate;


import { axiosPrivate } from "../api/axios";
import { useEffect } from "react";
import useRefreshToken from "./useRefreshToken";
import useAuth from "./useAuth";
import { AxiosRequestConfig, AxiosError } from 'axios';


interface RetryAxiosRequestConfig extends AxiosRequestConfig {
  _retry?: boolean;
}

const useAxiosPrivate = () => {
  const refresh = useRefreshToken();
  const { auth } = useAuth();

  useEffect(() => {

    const requestIntercept = axiosPrivate.interceptors.request.use(
      (config: any) => {
        if (!config.headers['Authorization']) {
          config.headers['Authorization'] = `Bearer ${auth?.accessToken}`;
        }
        return config;
      }, (error: any) => Promise.reject(error)
    );
    
    const responseIntercept = axiosPrivate.interceptors.response.use(
      response => response,
      async (error: AxiosError) => {
        const prevRequest = error?.config as RetryAxiosRequestConfig;
        if (error?.response?.status === 403 && !prevRequest?._retry) {
          prevRequest._retry = true;
          const newAccessToken = await refresh();
          prevRequest.headers['Authorization'] = `Bearer ${newAccessToken}`;
          return axiosPrivate(prevRequest);
        }
        return Promise.reject(error);
      }
    );

    return () => {
      axiosPrivate.interceptors.request.eject(requestIntercept);
      axiosPrivate.interceptors.response.eject(responseIntercept);
    }
  }, [auth, refresh])

  return axiosPrivate;
}

export default useAxiosPrivate;