import { createContext, useState, ReactNode } from "react";

interface AuthContextType {
  auth: any; 
  setAuth: React.Dispatch<React.SetStateAction<any>>; 
  persist: boolean;
  setPersist: React.Dispatch<React.SetStateAction<boolean>>;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

interface AuthProviderProps {
  children: ReactNode;
}

export const AuthProvider = ({ children }: AuthProviderProps) => {
  const [auth, setAuth] = useState<any>({}); 
  const [persist, setPersist] = useState<boolean>(JSON.parse(localStorage.getItem("persist") || "false"));

  return (
    <AuthContext.Provider value={{ auth, setAuth, persist, setPersist }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;