import {  Button } from '@chakra-ui/react'

function ButtonField(props:{
  Name?: any;
  size?: string;
  color?: string;
  onClick?: any;
  disabled?: boolean;
  type?: any;
  className?: string;
  extra?: any;
  id?: string;
  isLoading?: boolean;
}) {
  const {
    Name,
    size,
    color,
    onClick,
    disabled,
    type,
    extra,
    id,
    isLoading,
  } = props;

  return (
    <Button
      id={id}
      type={type}
      size={size}
      colorScheme={color}
      isLoading={isLoading}
      onClick={onClick}
      disabled={disabled}
      className={`w-full p-2 hover:shadow-lg rounded-md bg-brand-500 py-3 text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200 ${extra}`}
    >
      {Name}
    </Button>
  );

}

export default ButtonField;