import React, { useState } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
} from '@chakra-ui/react';
import ButtonField from 'components/fields/ButtonField';
import { toast } from 'react-toastify';
import Card from 'components/card';

import useAxiosPrivate from "hooks/useAxiosPrivate";
import BackendRoutes from "api/BackendRoutes/BackendRoutes";

function DeleteCustomer(props: any) {
    const { isOpen, onClose, selectedData, onSubmitDelete } = props;
    const AxiosPrivate = useAxiosPrivate();

  const DeleteCustomerRequest = async (data: any) => {
    try {
      const Response =  await AxiosPrivate.get(BackendRoutes.DeleteCustomer + selectedData._id);
      if(Response){
          onSubmitDelete(true);
          toast.success(Response?.data?.message);
          onClose();
        }
    } catch (error: any) {
        toast.error("Error:" + (error as any).response?.data?.message);
    }
    
  };


  return (
    <div>
      <Modal isOpen={isOpen} onClose={onClose} size={'xl'}>
    <ModalOverlay className="bg-[#000] !opacity-30" />
    <ModalContent className="!z-[1002] !m-auto !w-max min-w-[350px] !max-w-[50%] md:top-[4vh]">

        <ModalBody>
            <Card extra="px-[30px] pt-[35px] pb-[20px] max-w-[950px] flex flex-col !z-[1004]">
          <h1 className="mb-[20px] text-2xl font-bold">Delete Customer</h1>
          <p className="mb-[20px]">
            You are about to delete this customer. 
            <br /> Are you sure you want to continue?
          </p>
          <p>
            Note: If the customer is linked to any current or past jobs they will not be deleted but hidden. This is done to keep the integrity of the data.
          </p>
          <hr className="my-[20px]" />

        
              
                <div className="grid grid-cols-2 grid-rows-1 gap-4">
                    <div className="mr-[15%]">
                        <button 
                        className="w-full hover:shadow-lg rounded-md bg-red-500 py-3 text-base font-medium text-white transition duration-200 hover:bg-red-600 active:bg-red-700 dark:bg-brand-400 dark:text-white dark:hover:bg-red-300 dark:active:bg-red-200"
                        type="button"
                        onClick={onClose}
                        >
                            Take me back!
                        </button>
                        
                    </div>
                    <div >
              <ButtonField
                Name="Delete Customer"
                color="clear"
                type={'submit'}
                onClick={DeleteCustomerRequest}
              />
                    </div>
                </div>
            </Card>
            </ModalBody>
           
        </ModalContent>
      </Modal>
    </div>
  );
}

export default DeleteCustomer;
