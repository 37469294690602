import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import "./index.css";

import App from "./App";
import { AuthProvider } from './context/AuthProvider';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ThemeProvider } from "@material-tailwind/react";


const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <BrowserRouter>
    <AuthProvider>
        <ThemeProvider>
        <Routes>
          <Route path="/*" element={<App />} />
        </Routes>
        </ThemeProvider>
      </AuthProvider>          
      <ToastContainer />
  </BrowserRouter>
);
