import Avatar from 'react-avatar';
import banner from "assets/img/profile/banner.png";
import Card from "components/card";
import dayjs from "dayjs";

interface BannerProps {
  profileData: any; // replace 'any' with the actual type if known
}

const Banner: React.FC<BannerProps> = ({ profileData }) => {

  const formatContactNumber = (number: string) => {
    const cleaned = ('' + number).replace(/\D/g, '');
    const match = cleaned.match(/^(\d{2})(\d{4})(\d{6})$/);
    if (match) {
      return '+' + match[1] + ' ' + match[2] + ' ' + match[3];
    }
    return "No Contact Number";
  };

  return (
    <Card extra={"items-center w-full h-full p-[16px] bg-cover"}>
      {/* Background and profile */}
      <div
        className="relative mt-1 flex h-32 w-full justify-center rounded-md bg-cover"
        style={{ backgroundImage: `url(${banner})` }}
      >
        <div className="absolute -bottom-12 flex h-[87px] w-[87px] items-center justify-center rounded-full border-[4px] border-white dark:!border-navy-700">
        <Avatar 
            src={ profileData.profilePic ? profileData.profilePic : ""}
            name={profileData.user ? profileData.user : "No Account Name"} 
            size="80" 
            round={true}
            textSizeRatio={2.5}
            className="flex cursor-pointer font-semibold text-gray-600 dark:text-white items-center justify-center text-center"
            />
        </div>
      </div>

      {/* Name and position */}
      <div className="mt-16 flex flex-col items-center">
        <h4 className="text-xl font-bold text-navy-700 dark:text-white">
          {profileData.firstName + " " + profileData.lastName}
        </h4>
        <h5 className="text-base font-normal text-gray-600">
          {profileData.email}
        </h5>
      </div>

      {/* Post followers */}
      <div className="mb-3 mt-6 flex gap-4 md:!gap-14">
        <div className="flex flex-col items-center justify-center">
          <h4 className="text-2xl font-bold text-navy-700 dark:text-white">
            {dayjs(profileData.Joined).format("DD  MMM YYYY")}
          </h4>
          <p className="text-sm font-normal text-gray-600">Joined</p>
        </div>
        <div className="flex flex-col items-center justify-center">
          <h4 className="text-2xl font-bold text-navy-700 dark:text-white">
            {formatContactNumber(profileData.contactNumber)}
          </h4>
          <p className="text-sm font-normal text-gray-600">Contact Number</p>
        </div>
        
      </div>
    </Card>
  );
};

export default Banner;
