import Card from "components/card";
import MiniCalendar from "components/calendar/MiniCalendar";
const Calender = () => {
  return (
    <Card extra={"w-full h-full p-4"}>
      {/* Your storage */}
      <div className="mb-auto flex flex-col items-start justify-start">

        <div className=" flex w-full items-center justify-center mt-2">
          <MiniCalendar />
        </div>
      </div>
    </Card>
  );
};

export default Calender;
