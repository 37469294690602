import React from 'react';
import Footer from 'components/footer/FooterAuthCentered';

const Centered = (props: { maincard: JSX.Element }) => {
  const { maincard } = props;
  return (
    <div className="flex min-h-screen w-full flex-col self-center justify-self-center overflow-hidden lg:mx-0">
      {maincard}
      <Footer />
    </div>
  );
};

export default Centered;
