import axios from "../api/axios";
import useAuth from "./useAuth";

const useLogout = () => {
  const { setAuth } = useAuth() as any;

  const logout = async (): Promise<void> => {
    setAuth({});
    try {
     await axios('/logout', { withCredentials: true });
    } catch (err: any) { 
      console.error(err); //TODO Add winston logging
    }
  }

  return logout;
}

export default useLogout;