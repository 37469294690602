
import  Datepicker   from 'tailwind-datepicker-react';
import { HiArrowSmLeft, HiArrowSmRight } from 'react-icons/hi';

function DatePicker (props: {
  placeholder: string;
  show: boolean;
  setShow: (show: boolean) => void;
  value: Date;
  onChange: (date: Date) => void;
  autoHide?: boolean;
  todayBtn?: boolean;
  clearBtn?: boolean;
  clearBtnText?: string;
  theme?: {
    background?: string;
    todayBtn?: string;
    clearBtn?: string;
    icons?: string;
    text?: string;
    disabledText?: string;
    input?: string;
    inputIcon?: string;
    selected?: string;
  };
  datepickerClassNames?: string;
  defaultDate?: any;
  inputPlaceholderProp?: string;
  language?: string;
  disabledDates?: Date[];
  weekDays?: string[];
  inputNameProp?: string;
  inputIdProp?: string;
  inputDateFormatProp?: {
    day?: string;
    month?: string;
    year?: string;
  };
  icons?: {
    prev: () => JSX.Element;
    next: () => JSX.Element;
  };
  id?:string;
  label?:string;
  variant?:string;
  error?: any;
  errorMessage?: any;
}){
  const { id, label, variant, error, errorMessage } = props;
  const DatePickerOptions = {
    autoHide: true,
    todayBtn: true,
    clearBtn: true,
    clearBtnText: "Clear",
    theme: {
      background: "dark:border-gray-800 dark:bg-navy-700 dark:text-white",
      todayBtn: "rounded-md bg-brand-500 px-2 py-2 text-base rounded-md font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200",
      clearBtn: "rounded-md bg-brad-600 dark:hover:bg-brand-700 dark:bg-brand-500 border-none",
      icons: "rounded-md px-2 py-2 text-base rounded-md font-medium transition duration-200 hover:bg-brand-600 hover:text-white active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200",
      text: "p-2 text-base rounded-md hover:bg-brand-500 hover:text-white font-medium transition duration-200 active:bg-brand-300 dark:hover:bg-brand-300 dark:text-white ",
      inputIcon: "",
      disabledText: "",
      input: "h-12 w-full mt-2 items-center justify-center rounded-md border bg-white/0 text-sm outline-none dark:border-gray-800 dark:bg-navy-800 dark:text-white",
      selected: "bg-brand-800 text-base rounded-md font-medium text-white transition duration-200 hover:border-gray-400 focus:border-blue-500 focus:ring focus:ring-blue-500/20 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-300 ",
    },
    icons: {
      prev: () => <span><HiArrowSmLeft /></span> ,
      next: () => <span><HiArrowSmRight /></span>,
    },
    datepickerClassNames: "top-42",
    defaultDate: new Date() ? null : new Date(),
    inputPlaceholderProp: props.placeholder,
    language: "en-gb",
    disabledDates: [] as Date[],
    weekDays: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
    inputNameProp: "date",
    inputIdProp: "date",
    inputDateFormatProp: {
      day: "numeric",
      month: "short",
      year: "numeric",
    } as any,
  };

  return (
    <div>
  <label
        htmlFor={id}
        className={`text-sm text-navy-700 dark:text-white ${
          variant === "auth" ? "ml-1.5 font-medium" : "ml-3 font-bold"
        }`}
      >
        {label}
      </label>
      <Datepicker
        options={DatePickerOptions}
        show={props.show}
        setShow={props.setShow}
        value={props.value}
        onChange={props.onChange}
      />
      {error && (
          <span className="text-red-500 text-sm">
            {(errorMessage) as string}
          </span>
      )}
    </div>
  );
};

export default DatePicker;