import React, { useEffect } from "react";
import Card from "components/card";
import { BsArrowRight } from "react-icons/bs";

import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from "@tanstack/react-table";

type RowObj = {
  "Transaction Description": string;
  "Transaction Date": string;
  "Transaction Type": string;
  "Debit Amount": number;
  "Credit Amount": number;
  "Balance": number;
};
const columnHelper = createColumnHelper<RowObj>();

// const columns = columnsDataCheck;
export default function ComplexTable(props: { tableData: any }) {
  const { tableData } = props;

  const [sorting, setSorting] = React.useState<SortingState>([]);
  const columns = [
    columnHelper.accessor("Transaction Description", {
      id: "Transaction Description",
      header: () => (
        <p className="text-sm font-bold text-gray-600 dark:text-white">
          Transaction Name
        </p>
      ),
      cell: (info) => (
        <p className="text-sm font-bold text-navy-700 dark:text-white">
          {info.getValue()}
        </p>
      ),
    }),
    columnHelper.accessor("Transaction Date", {
      id: "Transaction Date",
      header: () => (
        <p className="text-sm font-bold text-gray-600 dark:text-white">
          Date
        </p>
      ),
      cell: (info) => (
        <p className="text-sm font-bold text-navy-700 dark:text-white">
          {info.getValue()}
        </p>
      ),
    }),
    columnHelper.accessor("Debit Amount", {
      id: "Debit Amount",
      header: () => (
        <p className="text-sm font-bold text-gray-600 dark:text-white">
          Debit
        </p>
      ),
      cell: (info) => (
        <p className="text-sm font-bold text-navy-700 dark:text-white">
        {info.getValue().toLocaleString('en-US', { style: 'currency', currency: 'GBP' })}
        </p>
      ),
    }),
    columnHelper.accessor("Credit Amount", {
      id: "Credit Amount",
      header: () => (
        <p className="text-sm font-bold text-gray-600 dark:text-white">
          Credit
        </p>
      ),
      cell: (info) => (
        <p className="text-sm font-bold text-navy-700 dark:text-white">
        {info.getValue().toLocaleString('en-US', { style: 'currency', currency: 'GBP' })}
        </p>
      ),
    }),
    columnHelper.accessor("Balance", {
      id: "Balance",
      header: () => (
        <p className="text-sm font-bold text-gray-600 dark:text-white">
          Balance
        </p>
      ),
      cell: (info) => (
        <p className="text-sm font-bold text-navy-700 dark:text-white">
        {info.getValue().toLocaleString('en-US', { style: 'currency', currency: 'GBP' })}
        </p>
      ),
      // cell: (info) => (
      //   <div
      //     className={`text-sm font-bold ${
      //       info.getValue()[0] === "-"
      //         ? "font-medium text-red-500"
      //         : "font-medium text-green-500"
      //     }`}
      //   >
      //     {info.getValue()}
      //   </div>
      // )

    }),
  ]; // eslint-disable-next-line
  const [data, setData] = React.useState(() => [...tableData]);
  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: true,
  });

  useEffect(() => {
    setData(tableData);
  }, [tableData]);
  return (
    <Card extra={"h-full w-full pt-6 pb-8 px-8"}>
      <div className="flex items-center justify-between">
        <p className="text-lg font-bold text-navy-700 dark:text-white">
          Recent Bank Statements
        </p>
      </div>

      <div className="mt-2 overflow-x-scroll xl:overflow-x-hidden">
        <table className="w-full">
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id} className="!border-px !border-gray-400">
                {headerGroup.headers.map((header) => {
                  return (
                    <th
                      key={header.id}
                      colSpan={header.colSpan}
                      onClick={header.column.getToggleSortingHandler()}
                      className="cursor-pointer border-b border-white/0 pt-4 pb-2 pr-4 text-start"
                    >
                      <div className="items-center justify-between text-xs text-gray-200">
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                        {{
                          asc: "",
                          desc: "",
                        }[header.column.getIsSorted() as string] ?? null}
                      </div>
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>
          <tbody>
            {table
              .getRowModel()
              .rows.slice(0, 15)
              .map((row) => {
                return (
                  <tr key={row.id}>
                    {row.getVisibleCells().map((cell) => {
                      return (
                        <td
                          key={cell.id}
                          className="min-w-[150px] border-white/0 py-3  pr-4"
                        >
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
        <div className="mb-auto mt-2" />
      <div className="flex w-full items-center justify-end gap-1 hover:cursor-pointer">
        <div className="text-sm font-bold text-brand-500 transition-all hover:-translate-x-1 hover:cursor-pointer dark:text-white">
          View all
        </div>
        <div className="text-xl font-bold text-brand-500 transition-all hover:translate-x-1 hover:cursor-pointer dark:text-white">
          <BsArrowRight />
        </div>
      </div>
    </Card>
  );
}
