import Card from "components/card";
import ButtonField from "components/fields/ButtonField";
import InputPasswordField from "components/fields/InputPasswordField";
import PasswordStrengthBar from 'react-password-strength-bar';

import { useForm, Controller } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import validation from 'Schema/validation';

const Password = (props:any) => {
  const { newPasswordData  } = props;
  
  const { control, handleSubmit, reset , formState: { errors },
} = useForm(
  { resolver: zodResolver(validation.Password) }
);

  const onSubmit = (data: any) => {  newPasswordData(data); 
    reset({
      OldPassword: '',
      NewPassword: '',
      NewPasswordConfirm: '',
    }) };

  return (
    <Card extra={"w-full mt-3 px-6 py-6"}>
      {/* Header */}
      <div className="w-full px-2">
        <h4 className="text-xl font-bold text-navy-700 dark:text-white">
          Change Password
        </h4>
        <p className="mt-1 text-base text-gray-600">
          Here you can set your new password
        </p>
      </div>
      {/* inputs */}
      <div className="mt-9 grid grid-cols-1 gap-3">
      <form onSubmit={handleSubmit(onSubmit)}>

      <Controller
          name="OldPassword"
          control={control}
          render={({ field }) => (
            <InputPasswordField
              variant=""
              extra="mb-3"
              label="Old Password"
              placeholder="Your Old Password"
              id="username"
              onChange={field.onChange}
              value={field.value} 
              error={errors.OldPassword}
              errorMessage={errors.OldPassword?.message}
              />
          )}
          />

       <Controller
          name="NewPassword"
          control={control}
          render={({ field }) => (
            <>
            <InputPasswordField
              variant=""
              extra="mb-3"
              label="New Password"
              placeholder="Your New Password"
              id="username"
              onChange={field.onChange}
              value={field.value}
              error={errors.NewPassword}
              errorMessage={errors.NewPassword?.message} 
              />
              {field.value && <PasswordStrengthBar password={field.value} />}
              </>
          )}
        />
        
       <Controller
          name="NewPasswordConfirm"
          control={control}
          render={({ field }) => (
            <>
            <InputPasswordField
              variant=""
              extra="mb-3"
              label="New Password Confirmation"
              placeholder="Confirm New Password"
              id="username"
              onChange={field.onChange}
              value={field.value} 
              error={errors.NewPasswordConfirm}
              errorMessage={errors.NewPasswordConfirm?.message}
              />
              {field.value && <PasswordStrengthBar password={field.value} />}
              </>
          )}
        />
        <div className="mt-2 flex w-full justify-end">
        <ButtonField
          id="submit"
          type="submit"
          Name="Change Password"
          color="clear"
          onClick={() => {}}
          />
        </div>
          </form>
      </div>
      {/* full width inputs */}
    </Card>
  );
};

export default Password;
