import React, { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import InputField from 'components/fields/InputField';
import { zodResolver } from '@hookform/resolvers/zod';
import validationSchemas from 'Schema/validation';
import PhoneInput from 'components/fields/PhoneInput';
import Button from 'components/fields/ButtonField';

const UserInfo = (props: any) => {
  
  const { sendDataToStepOne, handleClick } = props;

  const [value, setValue] = useState("")
  const [inputs, setInputs] = useState<any>();

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(validationSchemas.Customerpage1),
  });

  function HandleNextClick(name:any) {  setInputs(name);  }

  const onSubmit = (data:any) => {
    sendDataToStepOne({ inputs: data });
    handleClick(inputs);
  };

  return (
    <div className="h-full w-full rounded-md px-3 pt-7 md:px-8">
      {/* Header */}
      <h4 className="pt-[5px] text-xl font-bold text-navy-700 dark:text-white">
        User Info
      </h4>

      {/* content */}
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="mt-7 grid grid-cols-1 gap-3 md:grid-cols-2">
          <Controller
            name="firstName"
            control={control}
            render={({ field }) => (
              <InputField
                variant=""
                extra="mb-3"
                label="First Name"
                placeholder={'e.g. John'}
                id="firstName"
                onChange={field.onChange}
                value={field.value}
                error={errors.firstName}
                errorMessage={errors.firstName?.message}
              />
            )}
          />

          <Controller
            name="lastName"
            control={control}
            render={({ field }) => (
              <InputField
                variant=""
                extra="mb-3"
                label="Last Name"
                placeholder={'e.g. Doe'}
                id="lastName"
                onChange={field.onChange}
                value={field.value}
                error={errors.lastName}
                errorMessage={errors.lastName?.message}
              />
            )}
          />

          <Controller
            name="contactNumber"
            control={control}
            render={({ field: { onChange, value } }) => (
              <PhoneInput
              country="GB"
              value={value}
              placeholder="e.g. 078 1234 5678"
              onChange={onChange}
              id='contactNumber'
              label='Contact Number'
              error={errors.contactNumber}
              errorMessage={errors.contactNumber?.message}
              /> 
            )}
          />

          <Controller
            name="email"
            control={control}
            render={({ field }) => (
              <InputField
                variant=""
                extra="mb-3"
                label="Email Address"
                placeholder={'e.g. example@exmaple.com'}
                id="email"
                onChange={field.onChange}
                value={field.value}
                error={errors.email}
                errorMessage={errors.email?.message}
              />
            )}
          />
        </div>
        <div className="pl-[55%]">
          <Controller
            name="NextButton"
            control={control}
            render={() => (
              <Button
                color="clear"
                type="submit"
                onClick={() => HandleNextClick('next')}
                Name="Next"
                className="h-[40px] w-[100px] text-sm"
              />
            )}
          />
        </div>
      </form>
    </div>
  );
};

export default UserInfo;
