import Card from "components/card";
import ReactApexChart from "react-apexcharts";
import "react-circular-progressbar/dist/styles.css";


const Suppliers = (props:any) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const {data} = props;

  const chartSeries = [data.CurrentAttachedJobs, data.JobCount];

  const Options:any = {
    labels: ["Current # of Attached Jobs", "Total # of Jobs"],
    colors: ["#7551FF", "#39B8FF"],
    chart: {
      type: "donut",
      width: "100%",
    },
    states: {
      hover: {
        filter: {
          type: "none",
        },
      },
    },
    legend: {
      show: false,
      position: "bottom",
    },
    dataLabels: {
      enabled: true,
      dropShadow: {
        enabled: true,
      },
    },
    plotOptions: {
      donut: {
        expandOnClick: false,
        labels: {
          show: true,
        },
      },
    },
    fill: {
      colors: ["#7551FF", "#39B8FF"],
    },
    tooltip: {
      enabled: true,
      theme: "dark",
    },
  };

  return (
    <Card extra={"w-full h-full p-3"}>
      {/* Header */}
      <div className="mb-8 mt-2 w-full">
        <h4 className="px-2 text-xl font-bold text-navy-700 dark:text-white">
          Stats Overview
        </h4>
        <p className="mt-2 px-2 text-base text-gray-600">
          View the number of jobs attached to this suppliers
        </p>
      </div>

      {/* Graph showing the number of jobs */}

     { (!data || !data.CurrentAttachedJobs  || !data.JobCount)  ? (
    <div className="flex items-center justify-center w-full h-full">
      <p className="text-lg font-bold text-gray-400">
        No jobs attached to this Supplier
      </p>
    </div>
  ) : (
    <>
    <ReactApexChart
            options={Options}
            series={chartSeries}
            type="donut"
            width="100%"
            height="100%" /><div className="mt-2 flex h-fit w-full items-center justify-center rounded-md border-[1px] border-gray-200 bg-white bg-clip-border px-4 py-4 shadow-md shadow-[#F3F3F3] dark:border-[#ffffff33] dark:!bg-navy-800 dark:text-white dark:shadow-none">
              <div className="flex flex-col items-center justify-center">
                <div className="flex items-center justify-center">
                  <p className="ml-1 text-xs font-normal text-gray-600">
                    Current # of Attached Jobs
                  </p>
                </div>
                <p className="mt-px text-xl font-bold text-navy-700 dark:text-white">
                  {data.CurrentAttachedJobs}
                </p>
              </div>
              <div className="mx-8 h-11 w-px bg-gray-400 dark:!bg-white/20" />
              <div className="flex flex-col items-center justify-center">
                <div className="flex items-center justify-center">
                  <p className="ml-1 text-xs font-normal text-gray-600">
                    Total # of Jobs
                  </p>
                </div>
                <p className="mt-px text-xl font-bold text-navy-700 dark:text-white">
                  {data.JobCount}
                </p>
              </div>
            </div></>
  )
}

        
      
    </Card>
  );
};

export default Suppliers;
