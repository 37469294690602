import React, { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import InputField from "components/fields/InputField";
import DropDown from 'components/fields/DropDownField';
import DatePicker from 'components/calendar/datePicker';
import Button from 'components/fields/ButtonField';
import InputFieldCurrency from 'components/fields/InputFieldCurrency';

import useAuth from 'hooks/useAuth';
import useAxiosPrivate from 'hooks/useAxiosPrivate';
import { zodResolver } from '@hookform/resolvers/zod';
import validationSchemas from 'Schema/validation';
import BackendRoutes from "api/BackendRoutes/BackendRoutes";


const UserInfo = (props:any) => {
  const { sendDataToStepOne, handleClick } = props;
  const { auth } = useAuth();
  const  axiosPrivate  = useAxiosPrivate();
  const {control, handleSubmit, formState: { errors } } = useForm({
    resolver: zodResolver(validationSchemas.JobPage1)
  });
  const [inputs, setInputs] = useState<any>();
  const [showStart , setShowStart] = useState(false);
  const [showEnd , setShowEnd] = useState(false);
  const [customers , setCustomers] = useState<any>([]);


  const handleCloseStart = (state: boolean | ((prevState: boolean) => boolean)) => { setShowStart(state); }
  

  const handleCloseEnd = (state: boolean | ((prevState: boolean) => boolean)) => { setShowEnd(state); }

  function HandleNextClick(name:any) {  setInputs(name);  }


  const onSubmit = (data:any) => {
    sendDataToStepOne({ inputs: data });
    handleClick(inputs);
  };

  const BackendCall = async () => {
    try {
      const Response = await axiosPrivate.get(BackendRoutes.GetJobFilterByCustomer + auth.UserID);
      setCustomers(Response.data);
    } catch (error:any) {
      if(error.response && error.response.status === 403){
        console.log('error');
        BackendCall();
      }else{
      console.log(error);
      }
    }
  };

  const JobTypes = [
    { value: 'Loft Conversion', label: 'Loft Conversion' },
    { value: 'One story Extension', label: 'One story Extension' },
    { value: 'Two story Extension', label: 'Two story Extension' },
    { value: 'Demolition + Rebuild', label: 'Demolition + Rebuild' },
    { value: 'New Home', label: 'New Home' },
    { value: 'Plumbing', label: 'Plumbing' },
    { value: 'Electrical work', label: 'Electrical work' },
    { value: 'Painting', label: 'Painting' },
];

  useEffect(() => {
      BackendCall();
  }, []);

  return (
    <div className="h-full w-full rounded-md px-3 pt-7 md:px-8">
      {/* Header */}
      <h4 className="pt-[5px] text-xl font-bold text-navy-700 dark:text-white">
        User Info
      </h4>

      {/* content */}
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="mt-7 grid grid-cols-1 gap-3 md:grid-cols-2">

      <Controller
            name="jobName"
            control={control}
            render={({ field }) => (
              <InputField
                variant=""
                extra="mb-3"
                label="Job Name"
                placeholder={ 'Enter a name for this job'
                }
                id="jobName"
                onChange={field.onChange}
                value={field.value}
                error={errors.jobName}
                errorMessage={errors.jobName?.message}
              />
            )}
          />
        <Controller
            name="jobCustomer"
            control={control}
            render={({ field }) => (
              <DropDown
                variant=""
                extra="mb-3"
                label="Select Customer"
                isSearchable={true}
                placeholder={
                  'Select a Customer for this Job' 
                }
                id="jobCustomer"
                onChange={field.onChange}
                value={field.value}
                error={errors.jobCustomer}
                errorMessage={errors.jobCustomer?.message}
                options={customers}
              />
            )}
          />

          <Controller
            name="jobType"
            control={control}
            render={({ field }) => (
              <DropDown
                variant=""
                extra="mb-3"
                label="Job Type"
                placeholder={
                  'Select what type of job this is' 
                }
                id="jobType"
                onChange={field.onChange}
                value={field.value}
                error={errors.jobType}
                errorMessage={errors.jobType?.message}
                options={JobTypes}
              />
            )}
          />



        <Controller
            name="EstimatedCost"
            control={control}
            render={({ field }) => (
            <InputFieldCurrency
              label="Estimated Cost"
              placeholder={'Enter the estimated cost of this job'}
              id="EstimatedCost"
              Name="EstimatedCost"
              onValueChange={() => field.value}
              onChange={field.onChange}
              decimalsLimit={2}
              // defaultValue={0}
              Prefix="£"
              variant=""
              extra="mb-3"
              error={errors.EstimatedCost}
              errorMessage={errors.EstimatedCost?.message}
            />
          )}
          />
        <Controller
            name="startDate"
            control={control}
            render={({ field }) => (
              <DatePicker
                variant=""
                label="Estimated Start Date"
                placeholder={
                  'Select the estimated start date for this job' 
                }
                id="startDate"
                onChange={field.onChange}
                value={field.value}
                error={errors.startDate}
                errorMessage={errors.startDate?.message}
                show={showStart}
                setShow={handleCloseStart}

              />
            )}
          />
        <Controller
            name="endDate"
            control={control}
            render={({ field }) => (
              <DatePicker
              variant=""
              label="Estimated End Date"
              placeholder={
                'Select the estimated end date for this job' 
              }
              id="startDate"
              onChange={field.onChange}
              value={field.value}
              error={errors.endDate}
              errorMessage={errors.endDate?.message}
              show={showEnd}
              setShow={handleCloseEnd}
              />
            )}  
          />
      </div>
      <div className="grid grid-cols-2 grid-rows-1 gap-7">
    <div >

    </div>
    <div className='pl-[55%]'>
      <Controller
          name="NextButton"
          control={control}
          render={() => (
            <Button
              color="clear"
              type="submit"
              onClick={() => HandleNextClick('next')}
              Name="Next"
              className="w-[100px] h-[40px] text-sm"
            />
          )}
      />
    </div>
    </div>
      </form>
    </div>
  );
};

export default UserInfo;
