import {useState, useEffect} from "react";
import { Helmet } from "react-helmet";
import Statistics from "./components/Statistics";
// Assets
// import { MdOutlineMapsHomeWork } from "react-icons/md";
import { LuWarehouse } from "react-icons/lu";
import Stats from "./components/Stats";
import UserReportsTable from "./components/UserReportsTable";
import SearchableTable from "./components/SearchTableUsersOverview";
import Button from "components/fields/ButtonField";
import {toast } from "react-toastify";
import useAxiosPrivate from "hooks/useAxiosPrivate";
import useAuth from "hooks/useAuth";
import BackendRoutes from "api/BackendRoutes/BackendRoutes";
import { useNavigate } from 'react-router-dom';



const UserReport = () => {
  const { auth } = useAuth();
  const navigate = useNavigate();
  const AxiosPrivate = useAxiosPrivate();
  const [Data, setData] = useState<any>([]);
  const [TableData, setTableData] = useState<any>([]);
  const [Customers, setCustomers] = useState<any>([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [SelectedCustomer, setSelectedCustomer] = useState<any>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const GetData = async () => {
    try {
      const response = await AxiosPrivate.get(
        BackendRoutes.GetSupplierList + auth.UserID
        );
        setData(response?.data?.activeSuppliers);
        setTableData(response?.data?.activeSuppliers?.suppliers);
    } catch (error: any) {
      if(error.response.status === 403){
        GetData();
      }else{
        console.log(error as any); //TODO : Handle Error
        toast.error("Error:" + (error as any).response?.data?.message);
      }
  }
  };

  console.log(Data);
  console.log(TableData);


  useEffect(() => {
    GetData();
    setIsLoading(false);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);
    
  return (
    <div className="mt-3 h-full w-full">
      <Helmet>
        <title>Suppler Overview | View all suppliers and edit them.</title>
        <meta
          name="description"
          content="Suppler Overview | View all suppliers and edit them."
        />
      </Helmet>
      <div className="mb-5 grid w-full grid-cols-1 gap-5 rounded-md md:grid-cols-2 xl:grid-cols-4">
        {/* statistics */}
        <Statistics
          icon={
            <div className="flex h-8 w-8 items-center justify-center rounded-full text-4xl text-brand-500 dark:!bg-navy-700 dark:text-white">
              <LuWarehouse />
            </div>
          }
          title="Total Suppliers"
          value={Data?.TotalNumber}
        />
        <Statistics
         icon={
          <div className="flex h-8 w-8 items-center justify-center rounded-full text-4xl text-green-500 dark:!bg-navy-700 dark:text-green-500">
            <LuWarehouse />
          </div>
        }
          title="Current active suppliers"
          value={Data?.ActiveNumber}
        />
        <Statistics
         icon={
          <div className="flex h-8 w-8 items-center justify-center rounded-full text-4xl text-red-500 dark:!bg-navy-700 dark:text-red-500">
            <LuWarehouse />
          </div>
        }
          title="Current Non active suppliers"
          value={Data?.InactiveNumber}
        />


        

      </div>
      {/* Stats and talbes*/}
      <div className="grid w-full grid-cols-11 gap-5 rounded-md">
        <div className="col-span-11 h-full w-full rounded-md md:col-span-11 lg:col-span-11 xl:col-span-11 2xl:col-span-11">
          {/* <UserReportsTable tableData={TableData} /> */}
          <SearchableTable tableData={TableData} />
        </div>
      </div>
    </div>
  );
};

export default UserReport;
