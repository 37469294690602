import React, { useEffect, useState } from "react";

import Banner from "./components/Banner";
import Holiday from "./components/Holiday";
import Notification from "./components/Notification";
import Pay from "./components/Pay";
import Calender from "./components/Calender";
import Stats from "./components/stats";

import { toast } from 'react-toastify';
import { Helmet } from 'react-helmet';
import secureLocalStorage from 'react-secure-storage';

import axios from "api/axios";
import BackendRoutes from "api/BackendRoutes/BackendRoutes";
import useAuth from "hooks/useAuth";


const ProfileOverview = () => {

  const { auth } = useAuth() as any;
  const [ProfileData, setProfileData] = useState<any>({});
  const [NotificationData, setNotificationData] = useState<any>({});
  const [HolidayData, setHolidayData] = useState<any>({});
  const [PayslipsData, setPayslipsData] = useState<any>({});


  const userProfiles = async () => {
    try {
      const response = await axios.get(
        BackendRoutes.GetUserDetails + auth.UserID ,
      );
      setProfileData(response?.data);
    } catch (error) {
      console.log(error); //TODO : Handle Error
      toast.error('Error:' + error);
    }
  };

  const userNotification = async () => {};

  const userHoliday = async () => {};

  const userPayslips = async () => {};



  useEffect(() => {
    userProfiles();
    userNotification();
    userHoliday();
    userPayslips();
  }, []);

  return (
    <div className="flex w-full flex-col gap-5 lg:gap-5">
      <Helmet>
        <title>{`${secureLocalStorage.getItem('Name')? secureLocalStorage.getItem('Name').toString() : "No Account Name"}'s - Profile `}</title>
      </Helmet>
      <div className="w-ful mt-3 flex h-fit flex-col gap-5 lg:grid lg:grid-cols-12">
        <div className="col-span-4 lg:!mb-0">
          <Banner 
          profileData={ProfileData}
          />
        </div>
        <div className="z-0 col-span-5 lg:!mb-0">
          <Stats />
        </div>

        <div className="col-span-3 lg:!mb-0">
          <Calender />
        </div>

      </div>
      {/* all Project & ... */}

      <div className="mb-4 grid h-full grid-cols-1 gap-5 lg:!grid-cols-12">
        <div className="col-span-5 lg:col-span-6 lg:mb-0 3xl:col-span-4">
          <Pay />
        </div>
        <div className="col-span-5 lg:col-span-6 lg:mb-0 3xl:col-span-5">
          <Holiday />
        </div>

        <div className="col-span-5 lg:col-span-12 lg:mb-0 3xl:!col-span-3">
          <Notification />
        </div>
      </div>
    </div>
  );
};

export default ProfileOverview;
