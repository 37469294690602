const Statistics = (props: {
  icon?: JSX.Element;
  title: string;
  value: number | string;
  endContent?: JSX.Element;
}) => {
  const { icon, title, value, endContent } = props;
  return (
    <div className="border-gray-20 flex h-[88px] w-full items-center justify-between gap-3 rounded-[10px] border-[1px] bg-white bg-clip-border px-4 py-4 shadow-md shadow-[#F3F3F3] dark:border-[#ffffff33] dark:!bg-navy-800 dark:text-white dark:shadow-none">
      <div className="flex items-center gap-3">
        {icon}
        <div>
          <h5 className="text-sm font-medium leading-5 text-gray-600">
            {title}
          </h5>
          <p className="mt-1 text-2xl font-bold leading-6 text-navy-700 dark:text-white">
            {value}
          </p>
        </div>
      </div>

      {endContent}
    </div>
  );
};

export default Statistics;
