import React, { useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import InputField from 'components/fields/InputField';
import DropDown from 'components/fields/DropDownField';
import Button from 'components/fields/ButtonField';

import { zodResolver } from '@hookform/resolvers/zod';
import validationSchemas from 'Schema/validation';
import  Country  from 'Schema/countries';

const Address = (props: any) => {

  const { sendDataToStepTwo, handleClick } = props;

  const [inputs, setInputs] = useState<any>();

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(validationSchemas.Customerpage2),
  });

  function HandleNextClick(name: any) {
    setInputs(name);
  }

  function HandleBackClick(name:any) {  
    setInputs(name);
  }

  const onSubmit = (data: any) => {
    sendDataToStepTwo({ inputs: data });
    handleClick(inputs);
  };

  return (
    <div className="h-full w-full rounded-md px-3 pt-7 md:px-8">
      {/* Header */}
      <h4 className="pt-[5px] text-xl font-bold text-navy-700 dark:text-white">
        Address
      </h4>

      {/* content */}
      <form onSubmit={handleSubmit(onSubmit)}>
      <div className="mt-4">
        <Controller
          name="address1"
          control={control}
          render={({ field }) => (
            <InputField
              extra="mb-3"
              label="Address Line 1"
              placeholder="eg. Main Street 203"
              id="address1"
              type="text"
              onChange={field.onChange}
              value={field.value}
              error={errors.address1}
              errorMessage={errors.address1?.message}
            />
          )}
        />
        <Controller
          name="address2"
          control={control}
          render={({ field }) => (
            <InputField
              extra="mb-3"
              label="Address Line 2"
              placeholder="eg. Apartment, Floor"
              id="address2"
              type="text"
              onChange={field.onChange}
              value={field.value}
              error={errors.address2}
              errorMessage={errors.address2?.message}
            />
          )}
        />

        {/* inputs */}
        <div className="grid grid-cols-2 gap-3">
        <Controller
              name="country"
              control={control}
              render={({ field }) => (
                <DropDown
                  variant=""
                  extra="mb-3"
                  label="Country"
                  placeholder={'eg. United Kingdom'}
                  id="country"
                  onChange={field.onChange}
                  isSearchable={true}
                  value={field.value}
                  error={errors.country}
                  errorMessage={errors.country?.message}
                  options={Country}
                />
              )}
            />
         

          <div className="col-span-1 grid grid-cols-2 gap-3">
          <Controller
            name="city"
            control={control}
            render={({ field }) => (
              <InputField
                label="City"
                placeholder="eg. Oxford"
                id="city"
                type="text"
                onChange={field.onChange}
                value={field.value}
                error={errors.city}
                errorMessage={errors.city?.message}
              />
            )}
          />
        <Controller
              name="postcode"
              control={control}
              render={({ field }) => (
                <InputField
                  label="Post Code"
                  placeholder="OX4 2JY"
                  id="postcode"
                  type="text"
                  onChange={field.onChange}
                  value={field.value}
                  error={errors.postcode}
                  errorMessage={errors.postcode?.message}
                />
              )}
            />

          </div>
        </div>
      </div>
      <div className="grid grid-cols-2 grid-rows-1 gap-7">
        <div className="pr-[55%]">
          <Controller
            name="PreviousButton"
            control={control}
            render={() => (
              <Button 
              color="clear" 
              type="submit" 
              Name="Previous" 
              onClick={() => HandleBackClick('')}
              />
            )}
          />
        </div>
        <div className="pl-[55%]">
          <Controller
            name="NextButton"
            control={control}
            render={() => 
            <Button 
            color="clear" 
            type="submit" 
            Name="Next" 
            onClick={() => HandleNextClick('next')}
            />}
          />
        </div>
      </div>
    </form>
    </div>
  );
};

export default Address;
