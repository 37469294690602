import { useEffect, useState } from "react";
import Balance from "./components/Balance";
import MostVisited from "./components/MostVisited";
import OverallRevenue from "./components/OverallRevenue";
import YourCard from "./components/YourCard";
import YourTransfers from "./components/YourTransfers";

import { Helmet } from "react-helmet";
import { toast } from "react-toastify";
import secureLocalStorage from "react-secure-storage";

import useAxiosPrivate from "hooks/useAxiosPrivate";
import useAuth from "hooks/useAuth";
import BackendRoutes from "api/BackendRoutes/BackendRoutes";

// import tableDataMostVisited from "./variables/tableDataMostVisited";

const AdminDashboard = () => {
  const { auth } = useAuth() as any;
  const AxiosPrivate = useAxiosPrivate();
  const [data, setData] = useState<any>({});
  const [YourCardData, setYourCardData] = useState<any>({});
  const [tableData, setTableData] = useState<any>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const GetData = async () => {
    try {
      const response = await AxiosPrivate.get(
        BackendRoutes.GetAdminDashBoard + auth.UserID
      );
      setData(response?.data);
      setYourCardData(response?.data);
    } catch (error:any) {
      if(error.response.status === 403){
        GetData();
      }else{
        toast.error("Error:" + (error as any).response?.data?.message);
      }
    }
  };

  const fetchData = async () => {
    try {
      const response = await AxiosPrivate.get(
        BackendRoutes.GetAdminTableDashBoardData + auth.UserID
      );
      setTableData(response?.data);
    } catch (error:any) {
      if(error.response.status === 403){
        fetchData();
      }else{
        toast.error("Error:" + (error as any).response?.data?.message);
      }
    }
  };

  useEffect(() => {
    GetData();
    setIsLoading(false);
    setIsLoading(true);
    fetchData();
    setIsLoading(false);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  return (
    <div className="mt-3 flex h-full w-full flex-col gap-[20px] rounded-md xl:flex-row">
      <Helmet>
            <title>{`${secureLocalStorage.getItem('Name')}'s Admin Dashboard`}</title>
      </Helmet>

      <div className="h-full w-full rounded-md">
        {/* left side */}
        <div className="col-span-9 h-full w-full rounded-t-2xl xl:col-span-6">
          <div className="mb-5 grid grid-cols-6 gap-5">
            <div className="col-span-6 h-full w-full rounded-md 3xl:col-span-4">
              <OverallRevenue   data={data}  />
            </div>
            <div className="col-span-6 w-full 3xl:col-span-2">
              <Balance  balance={data}  />
            </div>
          </div>
          <div className="mt-5 grid w-full grid-cols-6 gap-5">
            <div className="col-span-6 md:col-span-4 3xl:col-span-4">
              <MostVisited tableData={tableData} />
            </div>
            <div className="col-span-6 3xl:col-span-2">
              <YourTransfers />
            </div>
          </div>
        </div>
      </div>
      {/* line */}
      <div className="flex w-0 bg-gray-200 dark:bg-[#ffffff33] xl:w-px" />

      {/* right section */}
      <div className="h-full w-full xl:w-[400px] xl:min-w-[300px] 2xl:min-w-[400px]">
        <YourCard
          balance={YourCardData} 
        />
      </div>
    </div>
  );
};

export default AdminDashboard;
