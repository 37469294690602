import { useState } from "react";
import Card from "components/card";
import InputField from "components/fields/InputField";
import DatePicker from "components/calendar/datePicker";
import ButtonField from "components/fields/ButtonField";
import dayjs from "dayjs";

import { useForm, Controller } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import validation from 'Schema/validation';


const Information = (props: any) => {
  const { userData, newUserData  } = props;
  
  const [showDate , setShowDate] = useState(false);

  const handleDateClose = (state: boolean | ((prevState: boolean) => boolean)) => { setShowDate(state); }

  const { control, handleSubmit, reset , formState: { errors },
} = useForm(
  { resolver: zodResolver(validation.UserUpdate) }
);

  const onSubmit = (data: any) => { 
    
    const dataMap = {
      email: data?.email || userData.email,
      firstName: data?.FirstName || userData.firstName,
      lastName: data?.LastName || userData.lastName,
      middleName: data?.MiddleName || userData.middleName,
      contactNumber: data?.ContactNumber || userData.contactNumber,
      dob: data?.dob || userData.DateOfBirth,
    };

      newUserData(dataMap);
      reset(
        {
          email: '',
          FirstName: '',
          MiddleName: '',
          LastName: '',
          PhoneNumber: '',
          dob: '',
        }
      )
  };

  return (
    <Card extra={"w-full mt-3 px-6 py-6"}>
      {/* Header */}
      <div className="w-full px-[8px]">
        <h4 className="text-xl font-bold text-navy-700 dark:text-white">
          Account Settings
        </h4>
        <p className="mt-1 text-base text-gray-600">
          Here you can change user account information
        </p>
      </div>
      {/* inputs */}
      <form onSubmit={handleSubmit(onSubmit)}>
      <div className="mt-[37px] grid grid-cols-1 gap-3 md:grid-cols-2">

            <InputField
              variant=""
              extra="mb-3"
              label="Username"
              disabled={ true }
              placeholder={userData.user ? userData.user : "Add a address"}
              id="username"
            />

        <Controller
          name="email"
          control={control}
          render={({ field }) => (
            <InputField
              variant=""
              extra="mb-3"
              label="Email Address"
              placeholder={userData.email ? userData.email : "Enter a email address"}
              id="email"
              onChange={field.onChange}
              onFocus={() => {console.log('focus')}}
              value={field.value}
              error={errors.email}
              errorMessage={errors.email?.message}
            />
          )}  
        />
        <Controller
          name="FirstName"
          control={control}
          render={({ field }) => (
            <InputField
              variant=""
              extra="mb-3"
              label="First Name"
              placeholder={userData.firstName ? userData.firstName : "Enter your first name"}
              id="firstName"
              onChange={field.onChange}
              value={field.value}
              error={errors.FirstName}
              errorMessage={errors.FirstName?.message}
            />
          )}  
        />
        <Controller
          name="MiddleName"
          control={control}
          render={({ field }) => (
            <InputField
              variant=""
              extra="mb-3"
              label="Middle Name"
              placeholder={userData.middleName ? userData.middleName : "Enter your middle name"}
              id="middleName"
              onChange={field.onChange}
              value={field.value}
              error={errors.MiddleName}
              errorMessage={errors.MiddleName?.message}
            />
          )}  
        />
        <Controller
          name="LastName"
          control={control}
          render={({ field }) => (
            <InputField
              variant=""
              extra="mb-3"
              label="Last Name"
              placeholder={userData.lastName ? userData.lastName : "Enter your Last name"}
              id="lastName"
              onChange={field.onChange}
              value={field.value}
              error={errors.LastName}
              errorMessage={errors.LastName?.message}
            />
          )}  
        />
        <Controller
          name="ContactNumber"
          control={control}
          render={({ field }) => (
            <InputField
              variant=""
              extra="mb-3"
              label="Phone Number"
              placeholder={userData.contactNumber ? userData.contactNumber : "Enter your phone number"}
              id="ContactNumber"
              onChange={field.onChange}
              value={field.value}
              error={errors.ContactNumber}
              errorMessage={errors.ContactNumber?.message}
            />
          )}
        />
      </div>
      {/* full width inputs */}
      <Controller
        name="dob"
        control={control}
        render={({ field: { onChange, value } }) => (
          <DatePicker
            variant=""
            label="Date of Birth"
            id="dob"
            show={showDate}
            setShow={handleDateClose}
            value={value}
            onChange={onChange}
            placeholder={userData.DateOfBirth ? dayjs(userData.DateOfBirth).format("DD MMM YYYY") : "Enter your date of birth"}
           />
        )}
      />

      {/* save button */}
      <div className="flex mt-3 w-full justify-end">
      <ButtonField
          id="submit"
          type="submit"
          Name="Save Changes"
          color="clear"
          onClick={() => {}}
          />
      </div>
          </form>
    </Card>
  );
};

export default Information;
