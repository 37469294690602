import Card from 'components/card';
import InputField from 'components/fields/InputField';
import ButtonField from 'components/fields/ButtonField';
import DropDown from 'components/fields/DropDownField';

import { useForm, Controller } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import validation from 'Schema/validation';

const Information = (props:any) => {

  const { addressData, newAddressData  } = props;

  const { control, handleSubmit, reset , formState: { errors },
} = useForm(
  { resolver: zodResolver(validation.AddressOptional) }
);

  const onSubmit = (data: any) => {  

    const dataMap = {
      Address1: data?.Address1 || addressData.lineOne,
      Address2: data?.Address2 || addressData.lineTwo,
      city: data?.city || addressData.city,
      country: data?.country || addressData.country,
      county: data?.county || addressData.county,
      postcode: data?.postcode || addressData.postcode,
    };

    newAddressData(dataMap);
    reset(
      {
        Address1: '',
        Address2: '',
        city: '',
        country: '',
        county: '',
        postcode: '',
      }
    )};
  return (
    <Card extra={'w-full mt-3 px-6 py-6'}>
      {/* Header */}
      <div className="w-full px-[8px]">
        <h4 className="text-xl font-bold text-navy-700 dark:text-white">
          Address Information
        </h4>
        <p className="mt-1 text-base text-gray-600">
          Here you can change address information
        </p>
      </div>
      {/* inputs */}
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="mt-[37px] grid grid-cols-1 gap-3 md:grid-cols-2">
          <Controller
            name="Address1"
            control={control}
            render={({ field }) => (
              <InputField
                variant=""
                extra="mb-3"
                label="Address 1"
                placeholder={
                  addressData.lineOne
                    ? addressData.lineOne
                    : 'Enter a first line of address'
                }
                id="Address1"
                onChange={field.onChange}
                value={field.value}
                error={errors.Address1}
                errorMessage={errors.Address1?.message}
              />
            )}
          />
          <Controller
            name="Address2"
            control={control}
            render={({ field }) => (
              <InputField
                variant=""
                extra="mb-3"
                label="Address 2"
                placeholder={
                  addressData.lineTwo
                    ? addressData.lineTwo
                    : 'Enter a second line of address'
                }
                id="Address1"
                onChange={field.onChange}
                value={field.value}
                error={errors.Address2}
                errorMessage={errors.Address2?.message}
              />
            )}
          />
          <Controller
            name="city"
            control={control}
            render={({ field }) => (
              <InputField
                variant=""
                extra="mb-3"
                label="City"
                placeholder={
                  addressData.city
                    ? addressData.city
                    : 'Enter a  city'
                }
                id="Address1"
                onChange={field.onChange}
                value={field.value}
                error={errors.city}
                errorMessage={errors.city?.message}
              />
            )}
          />
          <Controller
            name="country"
            control={control}
            render={({ field }) => (
              <DropDown
                variant=""
                label="Country"
                placeholder={
                  addressData.country
                    ? addressData.country
                    : 'Select a Country'
                }
                id="Address1"
                onChange={field.onChange}
                value={field.value}
                error={errors.country}
                errorMessage={errors.country?.message}
              />
            )}
          />
        {errors.county && (
          <span className="text-red-500 text-sm">
            {(errors.county.message) as string}
          </span>
        )}
          <Controller
            name="county"
            control={control}
            render={({ field }) => (
              <DropDown
                variant=""
                label="County"
                placeholder={
                  addressData.county
                    ? addressData.county
                    : 'Select a County'
                }
                id="Address1"
                onChange={field.onChange}
                value={field.value}
                error={errors.county}
                errorMessage={errors.county?.message}
              />
            )}
          />
          <Controller
            name="postcode"
            control={control}
            render={({ field }) => (
              <InputField
                variant=""
                extra="mb-3"
                label="Postcode"
                placeholder={
                  addressData.postcode
                    ? addressData.postcode
                    : 'Enter a postcode'
                }
                id="Address1"
                onChange={field.onChange}
                value={field.value}
                error={errors.postcode}
                errorMessage={errors.postcode?.message}
              />
            )}
          />
        </div>
      <div className="flex w-full justify-end">
        <ButtonField
          id="submit"
          type="submit"
          Name="Save Changes"
          color="clear"
          />
      </div>
          </form>
    </Card>
  );
};

export default Information;
