import React, { useEffect, useState } from "react";
import SearchIcon from "components/icons/SearchIcon";
import Card from "components/card";
import { MdChevronRight, MdChevronLeft } from "react-icons/md";
import { IoMdOpen } from "react-icons/io";
import { IoIosPersonAdd } from "react-icons/io";

import {
  PaginationState,
  createColumnHelper,
  useReactTable,
  ColumnFiltersState,
  getCoreRowModel,
  getFilteredRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFacetedMinMaxValues,
  getPaginationRowModel,
  getSortedRowModel,
  flexRender,
} from "@tanstack/react-table";
import { useNavigate } from 'react-router-dom';
import ButtonField from "components/fields/ButtonField";
// import EditCustomer from "./editCustomer";
// import DeleteCustomer from "./DeleteCustomer";

type RowObj = {
  supplierName: string;
  supplierEmail: string;
  supplierPhone: string;
  supplierCategory: string;
  options: any;
};

function CheckTable(props: any) {
  const { tableData, updateData } = props;
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpen2, setIsModalOpen2] = useState(false);
  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(
    []
  );
  const [globalFilter, setGlobalFilter] = React.useState("");
  const createPages = (count: number) => {
    let arrPageCount = [];

    for (let i = 1; i <= count; i++) {
      arrPageCount.push(i);
    }

    return arrPageCount;
  };

  const onEditClick = (info:any) => {
    navigate(`/main/supplier/view-supplier/${info._id}`);
  };

  const columns = [
    columnHelper.accessor("supplierName", {
      id: "supplierName",
      header: () => (
        <p className="text-xl font-bold text-gray-600 dark:text-white">
          Supplier Name
        </p>
      ),
      cell: (info: any) => (
        <div className="flex w-full items-center">
          <p className="font-medium text-navy-700 dark:text-white">
            {info.getValue()}
          </p>
        </div>
      ),
    }),
    columnHelper.accessor("supplierPhone", {
      id: "supplierPhone",
      header: () => (
        <p className="flex text-xl  font-bold text-gray-600 dark:text-white">
          Contact Number
        </p>
      ),
      cell: (info) => (
        <p className="text-sm font-bold text-navy-700 dark:text-white">
          {info.getValue()}
        </p>
      ),
    }),
    columnHelper.accessor("supplierEmail", {
      id: "supplierEmail",
      header: () => (
        <p className="flex text-xl  font-bold text-gray-600 dark:text-white">
          Email Address 
        </p>
      ),
      cell: (info) => (
        <p className="text-sm font-bold text-navy-700 dark:text-white">
          {info.getValue()}
        </p>
      ),
    }),
    columnHelper.accessor("supplierCategory", {
      id: "supplierCategory",
      header: () => (
        <p className="flex text-xl  font-bold text-gray-600 dark:text-white">
          Category 
        </p>
      ),
      cell: (info) => (
        <p className="text-sm font-bold text-navy-700 dark:text-white">
          {info.getValue()}
        </p>
      ),
    }),
    columnHelper.accessor("options", {
      id: "options",
      header: () => (
        <p className="flex justify-end mr-[15%] text-xl  font-bold text-gray-600 dark:text-white">
          Options
        </p>
      ),
      cell: (info) => (
        <p className="flex justify-end mr-[25%] text-sm font-bold text-navy-700 dark:text-white">
          <button className="max-h-fit w-full max-w-max cursor-pointer text-xl text-gray-600 hover:text-blue-300"
            onClick={()=>{onEditClick(info.cell.row.original)}}
            >
              <IoMdOpen />
            </button>
        </p>
      ),
    }),
  ]; // eslint-disable-next-line
  const [data, setData] = React.useState(() => [...tableData]);
  const [{ pageIndex, pageSize }, setPagination] =
    React.useState<PaginationState>({
      pageIndex: 0,
      pageSize: 6,
    });

  const pagination = React.useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize]
  );
  const table = useReactTable({
    data,
    columns,
    state: {
      columnFilters,
      globalFilter,
      pagination,
    },
    onPaginationChange: setPagination,
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
    debugTable: true,
    debugHeaders: true,
    debugColumns: false,
  });

  useEffect(() => {
    setData(tableData);
  }, [tableData]);

  const handleNew = () => {
    navigate('/main/supplier/new-supplier');
  };

  return (
    <Card extra={"w-full h-full mt-11 sm:mt-10 md:mt-8"}>
    <div className={"h-full w-full px-6 sm:overflow-auto"}>

      <div className="grid grid-cols-2 grid-rows-1 gap-1 pt-5 max-w-full rounded-md">
    <div >
        <div className="flex h-[45px] w-[400px] xs:w-[5%] flex-grow pr-10 items-center rounded-md bg-lightPrimary text-sm text-gray-600 dark:!bg-navy-900 dark:text-white">
          <SearchIcon />
          <input
            value={globalFilter ?? ""}
            onChange={(e: any) => setGlobalFilter(e.target.value)}
            type="text"
            placeholder="Search...."
            className="block w-full rounded-full bg-lightPrimary text-base text-navy-700 outline-none dark:!bg-navy-900 dark:text-white"
          />
        </div>

    </div>
    <div className="flex flex-grow items-end justify-end pl-[85%] ">

            <ButtonField
            color="clear" 
            type="submit" 
            Name={<IoIosPersonAdd />}
            onClick={handleNew}
            />

    </div>
</div>
    


          <div className="relative justify-end ">
          </div>

      <div className="mt-8 overflow-x-scroll xl:overflow-x-hidden">
        <table className="w-full">
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id} className="!border-px !border-gray-400">
                {headerGroup.headers.map((header) => {
                  return (
                    <th
                      key={header.id}
                      colSpan={header.colSpan}
                      onClick={header.column.getToggleSortingHandler()}
                      className="cursor-pointer border-b border-gray-200 pb-2 pr-4 pt-4 text-start dark:border-white/30"
                    >
                      <div className="items-center justify-between text-xs text-gray-200">
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                        {{
                          asc: "",
                          desc: "",
                        }[header.column.getIsSorted() as string] ?? null}
                      </div>
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>
          <tbody>
            {table
              .getRowModel()
              .rows.slice(0, 7)
              .map((row) => {
                return (
                  <tr key={row.id}>
                    {row.getVisibleCells().map((cell) => {
                      return (
                        <td
                          key={cell.id}
                          className="min-w-[150px] border-white/0 py-3  pr-4"
                        >
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
          </tbody>
        </table>
        {/* pagination */}
        <div className="mt-2 flex h-20 w-full items-center justify-between px-6">
          {/* left side */}
          <div className="flex items-center gap-3">
            <p className="> Show rows per page text-sm text-gray-700">
              Showing 6 rows per page
            </p>
          </div>
          {/* right side */}
          <div className="flex items-center gap-2">
            <button
              onClick={() => table.previousPage()}
              disabled={!table.getCanPreviousPage()}
              className={`linear flex h-10 w-10 items-center justify-center rounded-md bg-brand-500 p-2 text-lg text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200`}
            >
              <MdChevronLeft />
            </button>

            {createPages(table.getPageCount()).map((pageNumber, index) => {
              return (
                <button
                  className={`linear flex h-10 w-10 items-center justify-center rounded-md p-2 text-sm transition duration-200 ${
                    pageNumber === pageIndex + 1
                      ? "bg-brand-500 text-white hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
                      : "border-[1px] border-gray-400 bg-[transparent] dark:border-white dark:text-white"
                  }`}
                  onClick={() => table.setPageIndex(pageNumber - 1)}
                  key={index}
                >
                  {pageNumber}
                </button>
              );
            })}
            <button
              onClick={() => table.nextPage()}
              disabled={!table.getCanNextPage()}
              className={`linear flex h-10 w-10 items-center justify-center rounded-md bg-brand-500 p-2 text-lg text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200 `}
            >
              <MdChevronRight />
            </button>
          </div>
        </div>
      </div>
    </div>
  </Card>
  );
}

export default CheckTable;
const columnHelper = createColumnHelper<RowObj>();
