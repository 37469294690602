import axios, { AxiosInstance } from 'axios';

let BASE_URL: string = '';

if (process.env.NODE_ENV === 'production') {
    BASE_URL = process.env.REACT_APP_PRODUCTION || '';
} else if (process.env.NODE_ENV === 'development') {
    BASE_URL = process.env.REACT_APP_DEVELOPMENT || '';
}

const axiosInstance: AxiosInstance = axios.create({
    baseURL: BASE_URL
});

export default axiosInstance;

export const axiosPrivate: AxiosInstance = axios.create({
    baseURL: BASE_URL,
    headers: { 'Content-Type': 'application/json' },
    withCredentials: true
});