import React, { useState } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from '@chakra-ui/react';
import { useForm, Controller } from 'react-hook-form';
import InputField from 'components/fields/InputField';
import { zodResolver } from '@hookform/resolvers/zod';
import validationSchemas from 'Schema/validation';
import PhoneInput from 'components/fields/PhoneInput';
import ButtonField from 'components/fields/ButtonField';
import DropDown from 'components/fields/DropDownField';
import TextField from 'components/fields/TextField';
import Country from 'Schema/countries';
import { toast } from 'react-toastify';
import Card from 'components/card';

import useAxiosPrivate from "hooks/useAxiosPrivate";
import useAuth from "hooks/useAuth";
import BackendRoutes from "api/BackendRoutes/BackendRoutes";

function EditCustomer(props: any) {
    const { isOpen, onClose, selectedData, onSubmitEdit } = props;
    const { auth } = useAuth();
    const AxiosPrivate = useAxiosPrivate();

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(validationSchemas.Customerpage3),
  });

  const onSubmit = (data: any) => {
    try {
      const Response = AxiosPrivate.post( BackendRoutes.UpdateCustomer + selectedData._id,{
        CustomerFirstName: data.firstName ? data.firstName : selectedData?.FirstName,    
        CustomerLastName: data.lastName ? data.lastName : selectedData?.LastName,       
        CustomerEmail: data.email ? data.email : selectedData?.Email,          
        CustomerContact: data.contactNumber ? data.contactNumber : selectedData?.Phone,          
        address1: data.address1 ? data.address1 : selectedData?.Address1,      
        address2 : data.address2 ? data.address2 : selectedData?.Address2,       
        city : data.city ? data.city : selectedData?.City,                    
        country : data.country ? data.country : selectedData?.Country,        
        postcode : data.postcode ? data.postcode : selectedData?.PostalCode,       
        CustomerNotes : data.notes ? data.notes : selectedData?.Notes,      
        
        
      });
      if(Response){
          onSubmitEdit(true);
          toast.success("Customer Updated Successfully");
          onClose();
        }
    } catch (error: any) {
        toast.error("Error:" + (error as any).response?.data?.message);
    }
    
  };

  return (
    <div>
      <Modal isOpen={isOpen} onClose={onClose} size={'xl'}>
    <ModalOverlay className="bg-[#000] !opacity-30" />
    <ModalContent className="!z-[1002] !m-auto !w-max min-w-[350px] !max-w-[50%] md:top-[4vh]">

        <ModalBody>
            <Card extra="px-[30px] pt-[35px] pb-[20px] max-w-[950px] flex flex-col !z-[1004]">
          <form onSubmit={handleSubmit(onSubmit)}>
          <h1 className="mb-[20px] text-2xl font-bold">Update Customer</h1>
          <p className="mb-[20px]">
            You can update the customer details here as well as add notes about them.
          </p>
              <div className="mt-7 grid grid-cols-1 gap-3 md:grid-cols-2">
                <Controller
                  name="firstName"
                  control={control}
                  render={({ field }) => (
                    <InputField
                      variant=""
                      extra="mb-3"
                      label="First Name"
                      placeholder={
                        selectedData?.FirstName
                          ? selectedData?.FirstName
                          : 'No First Name'
                      }
                      id="firstName"
                      onChange={field.onChange}
                      value={field.value}
                      error={errors.firstName}
                      errorMessage={errors.firstName?.message}
                    />
                  )}
                />

                <Controller
                  name="lastName"
                  control={control}
                  render={({ field }) => (
                    <InputField
                      variant=""
                      extra="mb-3"
                      label="Last Name"
                      placeholder={
                        selectedData?.LastName
                          ? selectedData?.LastName
                          : 'No Last Name'
                      }
                      id="lastName"
                      onChange={field.onChange}
                      value={field.value}
                      error={errors.lastName}
                      errorMessage={errors.lastName?.message}
                    />
                  )}
                />

                <Controller
                  name="contactNumber"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <PhoneInput
                      country="GB"
                      value={value}
                      placeholder={
                        selectedData?.Phone
                          ? selectedData?.Phone
                          : 'No Contact Number'
                      }
                      onChange={onChange}
                      id="contactNumber"
                      label="Contact Number"
                      error={errors.contactNumber}
                      errorMessage={errors.contactNumber?.message}
                    />
                  )}
                />

                <Controller
                  name="email"
                  control={control}
                  render={({ field }) => (
                    <InputField
                      variant=""
                      extra="mb-3"
                      label="Email Address"
                      placeholder={
                        selectedData?.Email
                          ? selectedData?.Email
                          : 'No Email Address'
                      }
                      id="email"
                      onChange={field.onChange}
                      value={field.value}
                      error={errors.email}
                      errorMessage={errors.email?.message}
                    />
                  )}
                />
              </div>
              <div className="mt-4">
                <Controller
                  name="address1"
                  control={control}
                  render={({ field }) => (
                    <InputField
                      extra="mb-3"
                      label="Address Line 1"
                      placeholder={
                        selectedData?.Address1
                          ? selectedData?.Address1
                          : 'No Address'
                      }
                      id="address1"
                      type="text"
                      onChange={field.onChange}
                      value={field.value}
                      error={errors.address1}
                      errorMessage={errors.address1?.message}
                    />
                  )}
                />
                <Controller
                  name="address2"
                  control={control}
                  render={({ field }) => (
                    <InputField
                      extra="mb-3"
                      label="Address Line 2"
                      placeholder={
                        selectedData?.Address2
                          ? selectedData?.Address2
                          : 'No Address'
                      }
                      id="address2"
                      type="text"
                      onChange={field.onChange}
                      value={field.value}
                      error={errors.address2}
                      errorMessage={errors.address2?.message}
                    />
                  )}
                />

                {/* inputs */}
                <div className="grid grid-cols-2 gap-3">
                  <Controller
                    name="country"
                    control={control}
                    render={({ field }) => (
                      <DropDown
                        variant=""
                        extra="mb-3"
                        label="Country"
                        placeholder={
                          selectedData?.Country
                            ? selectedData?.Country
                            : 'No Country'
                        }
                        id="country"
                        onChange={field.onChange}
                        isSearchable={true}
                        value={field.value}
                        error={errors.country}
                        errorMessage={errors.country?.message}
                        options={Country}
                      />
                    )}
                  />

                  <div className="col-span-1 grid grid-cols-2 gap-3">
                    <Controller
                      name="city"
                      control={control}
                      render={({ field }) => (
                        <InputField
                          label="City"
                          placeholder={
                            selectedData?.City ? selectedData?.City : 'No City'
                          }
                          id="city"
                          type="text"
                          onChange={field.onChange}
                          value={field.value}
                          error={errors.city}
                          errorMessage={errors.city?.message}
                        />
                      )}
                    />
                    <Controller
                      name="postcode"
                      control={control}
                      render={({ field }) => (
                        <InputField
                          label="Post Code"
                          placeholder={
                            selectedData?.PostalCode
                              ? selectedData?.PostalCode
                              : 'No Post Code'
                          }
                          id="postcode"
                          type="text"
                          onChange={field.onChange}
                          value={field.value}
                          error={errors.postcode}
                          errorMessage={errors.postcode?.message}
                        />
                      )}
                    />
                  </div>
                </div>
                <div className="">
                  <Controller
                    name="notes"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <TextField
                        extra="mb-3"
                        label="Notes"
                        placeholder={
                          selectedData?.Notes
                            ? selectedData?.Notes
                            : 'Add some notes for this customer here'
                        }
                        id="notes"
                        onChange={onChange}
                        rows={3}
                        cols={50}
                      />
                    )}
                  />
                </div>
              </div>
              
                <div className="grid grid-cols-2 grid-rows-1 gap-4">
                    <div className="mr-[45%]">
                        <button 
                        className="w-full hover:shadow-lg rounded-md bg-red-500 py-3 text-base font-medium text-white transition duration-200 hover:bg-red-600 active:bg-red-700 dark:bg-brand-400 dark:text-white dark:hover:bg-red-300 dark:active:bg-red-200"
                        type="button"
                        onClick={onClose}
                        >
                            Cancel
                        </button>
                      
                        
                    </div>
                    <div >
              <ButtonField
                Name="Save"
                color="clear"
                type={'submit'}
                // onClick={onsubmit}
              />

                    </div>
                </div>
    
              
          </form>
            </Card>
            </ModalBody>
           
        </ModalContent>
      </Modal>
    </div>
  );
}

export default EditCustomer;
