import Card from 'components/card';
import Transaction from 'components/dataDisplay/Transaction';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaFileInvoice } from 'react-icons/fa6';
import { IoIosPeople } from 'react-icons/io';
import { LuWarehouse } from 'react-icons/lu';
//assets
import {
  MdCached,
  MdOutlineMapsHomeWork,
} from 'react-icons/md';

function YourCard(props: any) {
  const { balance } = props;
  const navigate = useNavigate();
  const [data, setData] = useState<any>([]);

  useEffect(() => {
    setData(balance.mostCommonTransactions);
  }, [balance]);

  return (
    <Card extra={'w-full h-fit 3xl:h-full px-3 pt-3 2xl:pb-5'}>
      {/* Header */}
      <div className="flex items-center justify-between px-3 pt-3">
        <h4 className="text-lg font-bold text-navy-700 dark:text-white">
          Quick Actions
        </h4>
        
      </div>
      {/* Card bellow info */}
      <div className="mt-4 w-full rounded-md bg-lightPrimary px-3 py-3 dark:bg-navy-700">
        <p className="text-sm font-medium text-gray-600">
          Here are some quick actions you can do that will help you manage your
          work quicker.
        </p>
      </div>

      {/* Buttons */}
      <div className="mt-10 flex w-full justify-between px-3 md:justify-around xl:justify-between">
        <div className="flex flex-col items-center justify-center">
          <button className="linear flex items-center justify-center rounded-full border-[1px] border-gray-200 bg-white p-3 text-2xl text-brand-500 shadow-2xl transition duration-200 hover:cursor-pointer hover:!bg-gray-50 active:!bg-gray-100 dark:border-white/20 dark:!bg-navy-700 dark:text-white dark:hover:!bg-white/20 dark:active:!bg-white/10">
            <FaFileInvoice />
          </button>
          <h5 className="mt-1 text-sm font-medium text-navy-700 dark:text-white">
            Invoice
          </h5>
        </div>

        <div className="flex flex-col items-center justify-center">
          <button className="linear flex items-center justify-center rounded-full border-[1px] border-gray-200 bg-white p-3 text-2xl text-yellow-500 shadow-2xl transition duration-200 hover:cursor-pointer hover:!bg-gray-50 active:!bg-gray-100 dark:border-white/20 dark:!bg-navy-700 dark:text-white dark:hover:!bg-white/20 dark:active:!bg-white/10"
          onClick={()=>{navigate('/main/jobs/view-all')}}
          >
            <MdOutlineMapsHomeWork />
          </button>
          <h5 className="mt-1 text-sm font-medium text-navy-700 dark:text-white">
            Job
          </h5>
        </div>

        <div className="flex flex-col items-center justify-center">
          <button className="linear flex items-center justify-center rounded-full border-[1px] border-gray-200 bg-white p-3 text-2xl text-green-500 shadow-2xl transition duration-200 hover:cursor-pointer hover:!bg-gray-50 active:!bg-gray-100 dark:border-white/20 dark:!bg-navy-700 dark:text-white dark:hover:!bg-white/20 dark:active:!bg-white/10">
            <IoIosPeople />
          </button>
          <h5 className="mt-1 text-sm font-medium text-navy-700 dark:text-white">
            Customer
          </h5>
        </div>

        <div className="flex flex-col items-center justify-center">
          <button className="linear flex items-center justify-center rounded-full border-[1px] border-gray-200 bg-white p-3 text-2xl text-yellow-500 shadow-2xl transition duration-200 hover:cursor-pointer hover:!bg-gray-50 active:!bg-gray-100 dark:border-white/20 dark:!bg-navy-700 dark:text-white dark:hover:!bg-white/20 dark:active:!bg-white/10">
            <LuWarehouse />
          </button>
          <h5 className="mt-1 text-sm font-medium text-navy-700 dark:text-white">
            Supplier
          </h5>
        </div>
      </div>
      {/* Transactions */}
      <h5 className="ml-2 mt-[42px] text-lg font-bold text-navy-700 dark:text-white">
        Common Transactions
      </h5>
      <p className="mb-2 ml-2 mt-1 text-sm font-medium text-gray-600">
        Here are some of your latest transactions.
      </p>

      <div>
        {data?.map((item: any, index: any) => {
          if (!item.description || !item.transactionType || !item.debitAmount) {
            return null; 
          }
          return (
            <div className="mb-3 mt-3" key={index}>
              <Transaction
                title={item.description}
                date={item.transactionType}
                sum={`£ ${item.debitAmount}`}
                mb="mb-[20px]"
                icon={
                  <MdCached className="text-brand-500 dark:text-white" />
                }
              />
            </div>
          );
        })}
      </div>
    </Card>
  );
}

export default YourCard;
