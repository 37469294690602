import {useState, useEffect} from "react";
import { Helmet } from "react-helmet";
import Statistics from "./components/Statistics";
// Assets
import { MdOutlineMapsHomeWork } from "react-icons/md";
import Stats from "./components/Stats";
import UserReportsTable from "./components/UserReportsTable";
import ButtonField from "components/fields/ButtonField";
import DropDown from "components/fields/DropDownField";
import {toast } from "react-toastify";
import useAxiosPrivate from "hooks/useAxiosPrivate";
import useAuth from "hooks/useAuth";
import BackendRoutes from "api/BackendRoutes/BackendRoutes";
import { useNavigate } from 'react-router-dom';



const UserReport = () => {
  const { auth } = useAuth();
  const navigate = useNavigate();
  const AxiosPrivate = useAxiosPrivate();
  const [Data, setData] = useState<any>([]);
  const [TableData, setTableData] = useState<any>([]);
  const [Customers, setCustomers] = useState<any>([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [SelectedCustomer, setSelectedCustomer] = useState<any>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const GetData = async () => {
    try {
      const response = await AxiosPrivate.get(
        BackendRoutes.GetAllJobList + auth.UserID
        );
        console.log(response.data);
        setData(response?.data);
        setTableData(response?.data?.FindJob);
    } catch (error: any) {
      if(error.response.status === 403){
        GetData();
      }else{
        console.log(error as any); //TODO : Handle Error
        toast.error("Error:" + (error as any).response?.data?.message);
      }
  }
  };

  const GetCustomer = async () => {
    try {
      const response = await AxiosPrivate.get(
        BackendRoutes.GetJobFilterByCustomer + auth.UserID
        );
        setCustomers(response?.data);
    } catch (error: any) {
      if(error.response.status === 403){
        GetCustomer();
      }else{
        console.log(error as any); //TODO : Handle Error
        toast.error("Error:" + (error as any).response?.data?.message);
      }
    }
  };

  const handleSelect =async (option:any) => {
    try {
      const response = await AxiosPrivate.get(
        BackendRoutes.GetAllJobList + auth.UserID + "/" + option?.value
        );
        setData(response?.data);
        setTableData(response?.data.FindJob);
    } catch (error: any) {
      console.log("error", error);
      toast.error(error.response.data.message);
    }
  };


  useEffect(() => {
    GetData();
    GetCustomer();
    setIsLoading(false);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  //Processing the data
  const MapStats= [
    {
      "TotalNumber": Data?.TotalNumber,
      "StartedNumber": Data?.StartedNumber,
      "PendingNumber": Data?.PendingNumber,
      "CompletedNumber": Data?.CompletedNumber,
      "EstCost": Data?.EstimatedCost,
    }
  ]

    
  return (
    <div className="mt-3 h-full w-full">
      <Helmet>
        <title>Job Overview | View all Jobs and edit them.</title>
      </Helmet>
      <div className="mb-5 grid w-full grid-cols-1 gap-5 rounded-md md:grid-cols-2 xl:grid-cols-4">
        {/* statistics */}
 <Statistics
          icon={
            <div className="flex h-8 w-8 items-center justify-center rounded-full text-4xl text-brand-500 dark:!bg-navy-700 dark:text-white">
              <MdOutlineMapsHomeWork />
            </div>
          }
          title="Total all time jobs"
          value={Data?.TotalNumber}
        />
        <Statistics
         icon={
          <div className="flex h-8 w-8 items-center justify-center rounded-full text-4xl text-green-500 dark:!bg-navy-700 dark:text-green-500">
            <MdOutlineMapsHomeWork />
          </div>
        }
          title="Current active jobs"
          value={Data?.StartedNumber}
        />
        <Statistics
         icon={
          <div className="flex h-8 w-8 items-center justify-center rounded-full text-4xl text-red-500 dark:!bg-navy-700 dark:text-red-500">
            <MdOutlineMapsHomeWork />
          </div>
        }
          endContent={
            <div className="flex pr-3 text-center text-xs text-gray-600"> 
            Pending <br/>  +  <br/> Not started</div>
          }
          title="Current Non active jobs"
          value={Data?.PendingNumber + Data?.CompletedNumber}
        />
        <div className="border-gray-20 w-full items- center justify-between rounded-[10px] border-[1px] bg-white bg-clip-border px-4 py-4 shadow-md shadow-[#F3F3F3] dark:border-[#ffffff33] dark:!bg-navy-800 dark:text-white dark:shadow-none" >
        <DropDown
          options={Customers}
          placeholder="Filter by Customer"
          onChange={handleSelect}
          value={SelectedCustomer}
          isSearchable={true}
          isClearable={true}
          isMulti={false}
          id="1"
        />
        <div className="grid grid-cols-2 mt-2 grid-rows-1 gap-4">
            <div >
        <ButtonField
            id="AddJob"
            Name="Add Job"
            type={"submit"}
            color="clear"
            onClick={() => navigate("/main/jobs/new-job")}
            isLoading={isLoading}
          />
            </div>
            <div >
            <ButtonField
            id="ClearFilter"
            Name="Clear Filter"
            type={"submit"}
            color="clear"
            onClick={() => GetData()}
            isLoading={isLoading}
          />
            </div>
        </div>

        </div>

      </div>

      <div className="grid w-full grid-cols-11 gap-5 rounded-md">
        <div className="col-span-11 h-full w-full rounded-md md:col-span-5 lg:col-span-4 xl:col-span-5 2xl:col-span-3">
          <div>
            <Stats
            StatsData={MapStats} 
            />
          </div>
        </div>
        <div className="col-span-11 h-full w-full rounded-md md:col-span-6 lg:col-span-7 xl:col-span-6 2xl:col-span-8">
          <UserReportsTable tableData={TableData} />
        </div> 
      </div> 
    </div>
  );
};

export default UserReport;
