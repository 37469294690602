import Card from "components/card";
import CardMenu from "components/card/CardMenu";
import Switch from "components/switch";

function Notification(props:any) {
  const {data, sendBackData} = props;


  return (
    <Card extra={"w-full h-auto mt-4 p-3"}>
      <div className="relative mb-2 flex items-center justify-between pt-1">
        <h4 className="text-xl px-2 font-bold text-navy-700 dark:text-white">
          Notifications 
        </h4>
        {/* <CardMenu /> */}
      </div>
        <p className=" mb-2 px-2 text-base text-gray-600">
          Chose which email you want to be sent to you.
         </p>
      <div className="flex flex-col px-2">
        {/* the custom checkbox desing added in src/index.js */}
        <div className="mt-3 flex items-center gap-3">
          <Switch id="switch1" checked={false} />
          <label
            htmlFor="checkbox1"
            className="text-base font-medium text-navy-700 dark:text-white"
          >
            Notify when a employee does hours 
          </label>
        </div>

        <div className="mt-4 flex items-center gap-3">
          <Switch id="switch2" checked={true} />
          <label
            htmlFor="checkbox2"
            className="text-base font-medium text-navy-700 dark:text-white"
          >
            Notify when customer views invoice
          </label>
        </div>

        <div className="mt-4 flex items-center gap-3">
          <Switch id="switch3" checked={false} />
          <label
            htmlFor="checkbox3"
            className="text-base font-medium text-navy-700 dark:text-white"
          >
            Notify when a new supplier is added
          </label>
        </div>

        <div className="mt-4 flex items-center gap-3">
          <Switch id="switch4" checked={false} />
          <label
            htmlFor="checkbox4"
            className="text-base font-medium text-navy-700 dark:text-white"
          >
            Notify when a new invoice is added
          </label>
        </div>

        <div className="mt-4 mb-10 flex items-center gap-3">
          <Switch id="switch5" checked={false}/>
          <label
            htmlFor="checkbox5"
            className="text-base font-medium text-navy-700 dark:text-white"
          >
            Notify when a new material is added
          </label>
        </div>
      
      </div>
    </Card>
  );
}

export default Notification;
