
import { useEffect, useState } from "react";

import Banner from "./components/Banner";
import Information from "./components/Information";
import Address from "./components/Address";
import Password from "./components/Password";
// import Social from "./components/Social";

import { toast } from 'react-toastify';
import { Helmet } from 'react-helmet';
import secureLocalStorage from 'react-secure-storage';

import axios from "api/axios";
import BackendRoutes from "api/BackendRoutes/BackendRoutes";
import useAuth from "hooks/useAuth";

const ProfileSetting = () => {

  const { auth } = useAuth() as any;
  const [ProfileData, setProfileData] = useState<any>({});
  const [AddressData, setAddressData] = useState<any>({});

  const userProfiles = async () => {
    try {
      const response = await axios.get(
        BackendRoutes.GetUserDetails + auth.UserID ,
      );
      setProfileData(response?.data);
    } catch (error) {
      console.log(error); //TODO : Handle Error
      toast.error('Error:' + error);
    }
  };

  const userAddress = async () => {
    try {
      const response = await axios.get(
        BackendRoutes.GetUserAddress + auth.UserID ,
      );
      setAddressData(response?.data);
    } catch (error) {
      console.log(error); //TODO : Handle Error
      toast.error('Error:' + error);
    }
  };
  
  const InformationData = async (data: any) => {
      try {
        const response = await axios.post(
          BackendRoutes.UpdateUserDetails + auth.UserID ,
          {
            email: data?.email,
            firstName: data?.FirstName,
            lastName: data?.LastName,
            middleName: data?.MiddleName,
            contactNumber: data?.PhoneNumber,
            dob: data?.dob,
          });
          if (response?.data.status === 201) {
            toast.success('Information Updated');
            userProfiles();
          }
          if(response?.data.status === 400){
            toast.error(response?.data.message);
          }
      } catch (error) {
        console.log(error); //TODO : Handle Error
        toast.error('Error:' + error);
      }
  }
  
  const AddressDataInfo = async (data: any) => {
    try {
      const response = await axios.post(
        BackendRoutes.UpdateUserAddress + auth.UserID ,
        {
          lineOne: data.Address1,
          lineTwo: data.Address2,
          city: data.city,
          county: data.county,
          country: data.country,
          postcode: data.postcode,
        });
        if (response?.data) {
          toast.success('Address Updated');
          userAddress();
        } 
    } catch (error) {
      console.log(error); //TODO : Handle Error
      toast.error('Error:' + error);
    }
  };

  const PasswordDataInfo = async (data: any) => {
    try{
      const response = await axios.post(
        BackendRoutes.UpdateUserPassword + auth.UserID ,
        {
          password: data.OldPassword,
          newPassword: data.NewPassword,
        });
        if (response?.data) {
          toast.success('Password Updated');
        } 
    }catch (error) {
      console.log(error); //TODO : Handle Error
      toast.error('Error:' + error);
    }
  };

  useEffect(() => {
    userProfiles();
    userAddress();
  }, []);


  return (
    <div className="mt-3 grid h-full w-full grid-cols-1 gap-5 lg:grid-cols-2">
      <Helmet>
        <title>{`${secureLocalStorage.getItem('Name')? secureLocalStorage.getItem('Name').toString() : "No Account Name"}'s - Profile Settings `}</title>
      </Helmet>
      <div className="rounded-[20px]">
        <div>
          <Banner 
          bannerData={ProfileData}
          />
        </div>
        <div className="mt-3">
          <Address 
          addressData={AddressData}
          newAddressData={AddressDataInfo}
          />
        </div>
      </div>
      <div className="">
        <div>
          <Information 
          userData={ProfileData}
          newUserData={InformationData}
          />
        </div>
        <div>
          <Password 
          newPasswordData={PasswordDataInfo}
          />
        </div>
      </div>
    </div>
  );
};

export default ProfileSetting;
