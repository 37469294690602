/*eslint-disable*/
import React from "react";
export default function Footer() {
  return (
    <div className="z-[1.5] mx-auto flex w-full max-w-[90%] flex-col items-center justify-between px-0 pt-12 pb-8 xl:w-[1170px] xl:max-w-[1170px] xl:flex-row">
      <p className="mb-4 text-center text-sm text-gray-600 sm:!mb-0 md:text-base">
        © 2022 - {new Date().getFullYear()} FHDesigns. All Rights Reserved.
      </p>
    </div>
  );
}
