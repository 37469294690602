import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import Banner from "./components/Banner";
import Suppliers from "./components/Suppliers";
import Notification from "./components/Notification";
import Invoices from "./components/Invoices";
import EditJob from "./components/EditJob";

import { toast } from 'react-toastify';
import { Helmet } from 'react-helmet';

import useAxiosPrivate from 'hooks/useAxiosPrivate';
import useAuth from "hooks/useAuth";
import BackendRoutes from "api/BackendRoutes/BackendRoutes";


const ProfileOverview = () => {
  const { id } = useParams()
  const { auth } = useAuth() as any;
  const  axiosPrivate  = useAxiosPrivate();
  
  //Loading + Flags
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [flags , setFlags] = useState<any>([{
    Supplier : false,
    Invoice : false,
    Notification: false,
    update: false
  }])

  //Data from the backend
  const [data, setData] = useState<any>([]);
  const [SupplierBackData, setSupplierBackData] = useState<any>([{
    Dropdown: [],
    Supplier: {},
  }]);

  //Data to be updated
  const [customerInfo , setCustomerInfo] = useState<any>([]);
  const [SupplierData, setSupplierData] = useState<any>([]);
  const [updateDate, setUpdateDate] = useState<any>({});
  const [jobStatus, setJobStatus] = useState<any>([]);

  const GetJobData = async () => {
    try {
      const response = await axiosPrivate.get(BackendRoutes.GetSelectedJob + id);
      setData(response.data.FindJob);
      setCustomerInfo(response.data.CustomerName);
    } catch (error:any) {
      toast.error(error.response.data.message);
    }
  }

  const GetSupplierData = async () => {
    try {
      const response = await axiosPrivate.get(BackendRoutes.GetSupplierList + auth.UserID);
      setSupplierBackData({ Dropdown: response.data.activeSuppliers.suppliers});


      const response2 = await axiosPrivate.get(BackendRoutes.GetJobsSupplierList + id);
      setSupplierBackData({ Supplier: response2.data});
      setIsLoading(false);
    } catch (error:any) {
      toast.error("There was an error getting the supplier data" + error.response.data.message);
    }
  };



  const HandleJobData = (data:any) => {
    setFlags({
      update: true
    });
    setUpdateDate(data);
    setIsLoading(true);
  }

  const HandleStatusChange = (data:any) => {
    setJobStatus(data);
    setIsLoading(true);
  }

  const HandleNotificationChanges = (data:any) => {
    console.log(data);
  }

  const handSupplierChanges = (data:any) => {
    setFlags({  Supplier : true })
    setSupplierData(data);
    setIsLoading(true);
  }

  const UpdateData = async () => {
    try {
      const response = await axiosPrivate.put(BackendRoutes.UpdateJob + id, {
        
        flag: flags,
        update:{ // Job Details
          jobName: updateDate?.inputs?.jobName,
          jobType: updateDate?.inputs?.jobType?.value,
          EstimatedCost: updateDate?.inputs?.EstimatedCost,
          JobStatus: jobStatus.inputs?.value,
          // JobActualCost: updateDate.inputs?.JobActualCost,
          startDate: updateDate?.inputs?.startDate,
          endDate: updateDate?.inputs?.endDate,
          address1: updateDate?.inputs?.address1,
          address2: updateDate?.inputs?.address2,
          country: updateDate?.inputs?.country,
          city: updateDate?.inputs?.city,
          postcode: updateDate?.inputs?.postcode
        },
        // Suppliers
        supplier: SupplierData,

      })
      if(response){
        toast.success(response.data.message);
        setFlags({
          Supplier : false,
          Invoice : false,
          Notification: false,
          update: false
        })
        GetData();
        setIsLoading(false);
      }
    } catch (error:any) {
      toast.error(error.response.data.message);
    }
  }

  const GetData = async () => {
    await GetJobData();
    await GetSupplierData();
  }

  useEffect(() => {
      UpdateData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading])

  useEffect(() => {  
    GetData(); // eslint-disable-next-line react-hooks/exhaustive-deps

 }, [])


  return (
    <div className="flex w-full flex-col gap-5 lg:gap-5">
      <Helmet>
        <title>Edit current job</title>
      </Helmet>
      <div className="w-ful mt-3 flex h-fit flex-col gap-5 lg:grid lg:grid-cols-12">
        <div className="col-span-4 lg:!mb-0">
          <Banner
            data={data}
            customer={customerInfo}
            sendBackData={HandleStatusChange} 
          />
          <Notification
            data={data}
            sendBackData={HandleNotificationChanges}  
          />
        </div>
        <div className="z-5 col-span-8 lg:!mb-0">
          <EditJob 
            data={data}
            sendBackData={HandleJobData}
            />
        </div>

      </div>
      {/* all Project & ... */}

      <div className="mb-4 grid h-full grid-cols-1 gap-5 lg:!grid-cols-12">
        <div className="col-span-5 lg:col-span-6 lg:mb-0 3xl:col-span-4">
          <Suppliers 
          id={id}
          backendData={SupplierBackData}
          UpdateData={handSupplierChanges}
          />  
        </div>
        <div className="col-span-5 lg:col-span-6 lg:mb-0 3xl:col-span-8">
          <Invoices />
        </div>
      </div>
    </div>
  );
};

export default ProfileOverview;
