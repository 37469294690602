import React, { useState } from 'react';
import Select from 'react-tailwindcss-select';

function DropDown(props: {
  id: string;
  label?: string;
  extra?: string;
  placeholder: string;
  variant?: string;
  disabled?: boolean;
  onChange: any;
  value: any;
  options?: any;
  isMulti?: boolean;
  isSearchable?: boolean;
  isClearable?: boolean;
  error?: any;
  errorMessage?: any;
}) {
  const {
    label,
    id,
    extra,
    placeholder,
    variant,
    disabled,
    onChange,
    value,
    options,
    isMulti,
    isSearchable,
    isClearable,
    error,
    errorMessage,
  } = props;

  const [selected, setSelected] = useState(false);

  const handleChange = (value: any) => {
    setSelected(true);
    onChange(value);
  };

  return (
    <div className={`${extra}`}>
      <div className="w-full h-full">
    {label && (
      <label
        htmlFor={id}
        className={`text-sm text-navy-700 dark:text-white ${
          variant === 'auth' ? 'ml-1.5 font-medium' : 'ml-3 font-bold'
        }`}
      >
        {label}
      </label>
    )}
      <Select
        onChange={handleChange}
        value={value}
        isDisabled={disabled}
        options={options}
        isSearchable={isSearchable}
        isClearable={isClearable}
        isMultiple={isMulti}
        placeholder={placeholder}
        primaryColor={''}
        classNames={{
          menuButton: ({ isDisabled }) =>
          `flex h-auto w-full z-10 mt-2 text-md p-0.5 
          ${selected ? 'text-black dark:text-white ' : 'text-gray-400'} 
          
          border rounded-md transition-all duration-300 focus:outline-none dark:border-gray-800 dark:bg-navy-800 dark:text-white ${
            isDisabled
              ? 'bg-gray-200 placeholder-gray-300'
              : 'bg-white hover:border-gray-400 placeholder-gray-300 focus:border-blue-500 focus:ring focus:ring-blue-500/20'
          }`,
          menu: 'absolute z-10 w-full bg-white shadow-lg border rounded-lg py-1 mt-1.5 text-sm dark:border-gray-700 dark:bg-navy-800 dark:text-white max-h-60 overflow-y-scroll',
          searchIcon: '',
          searchBox:
            'flex pl-8 w-full z-10 px-2 py-2 text-sm  bg-white border rounded shadow-sm focus:outline-none dark:border-gray-700 dark:bg-navy-800 dark:text-white',
            tagItem(value) {
              return `bg-brand-500 text-white rounded-md px-2 py-1 text-sm flex items-center justify-center line-clamp-1 overflow-ellipsis overflow-hidden dark:bg-brand-300 dark:hover:bg-brand-400 dark:hover:text-black dark`;
            },
            tagItemText: 'text-sm text-white',
            tagItemIconContainer: 'flex items-center justify-center px-1',
          
          list: 'py-1 overflow-y-auto',
          listItem: ({ isSelected }) =>
            `block transition z-10 duration-200 px-2 py-2 cursor-pointer select-none truncate rounded dark:border-gray-700 dark:bg-navy-800 dark:text-white${
              isSelected
                ? `text-white bg-brand-500 text-white`
                : `text-gray-500 hover:bg-brand-500 hover:text-white dark:text-white dark:hover:bg-brand-400 dark:hover:text-white`
            }`,
        }}
      />
      {error && (
        <span className="text-sm text-red-500">{errorMessage as string}</span>
      )}
    </div>
    </div>
  );
}

export default DropDown;
