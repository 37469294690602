import React, { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import InputField from 'components/fields/InputField';
import PhoneInput from 'components/fields/PhoneInput';
import DropDown from 'components/fields/DropDownField';
import Button from 'components/fields/ButtonField';

import { zodResolver } from '@hookform/resolvers/zod';
import validationSchemas from 'Schema/validation';

const UserInfo = (props: any) => {
  const { sendDataToStepOne, handleClick } = props;

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(validationSchemas.Supplier1),
    mode: 'onChange',
  });
  const [inputs, setInputs] = useState<any>();

  function HandleNextClick(name: any) {
    setInputs(name);
  }

  const onSubmit = (data: any) => {
    sendDataToStepOne({ inputs: data });
    handleClick(inputs);
  };


  const Categories = [
    { value: 'General', label: 'General' },
    { value: 'Electrical', label: 'Electrical' },
    { value: 'Plumbing', label: 'Plumbing' },
    { value: 'Flooring', label: 'Flooring' },
    { value: 'Timber', label: 'Timber' },
    { value: 'Roofing', label: 'Roofing' },
    { value: 'Painting & Decorating', label: 'Painting & Decorating' },
    { value: 'Landscaping', label: 'Landscaping' },
    { value: 'Gardening', label: 'Gardening' },
    { value: 'Cleaning', label: 'Cleaning' },
    { value: 'Fencing', label: 'Fencing' },
    { value: 'Windows', label: 'Windows' },
    { value: 'Doors', label: 'Doors' },
    { value: 'Gutters', label: 'Gutters' },
    { value: 'Scaffolding', label: 'Scaffolding' },
    { value: 'Misc', label: 'Misc' },
];

  return (
    <div className="h-full w-full rounded-md px-3 pt-7 md:px-8">
      {/* Header */}
      <h4 className="pt-[5px] text-xl font-bold text-navy-700 dark:text-white">
        User Info
      </h4>

      {/* content */}
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="mt-7 grid grid-cols-1 gap-3 md:grid-cols-2">
          <Controller
            name="supplierName"
            control={control}
            render={({ field }) => (
              <InputField
                variant=""
                extra="mb-3"
                label="Supplier Name"
                placeholder={'Enter a name for the supplier'}
                id="supplierName"
                onChange={field.onChange}
                value={field.value}
                error={errors.supplierName}
                errorMessage={errors.supplierName?.message}
              />
            )}
          />
          <Controller
            name="supplierCategory"
            control={control}
            render={({ field }) => (
              <DropDown
                variant=""
                extra="mb-3"
                label="Select Category"
                isMulti={false}
                isClearable={true}
                isSearchable={true}
                placeholder={'Select a category for the supplier'}
                id="supplierCategory"
                onChange={field.onChange}
                value={field.value}
                error={errors.supplierCategory}
                errorMessage={errors.supplierCategory?.message}
                options={Categories}
              />
            )}
          />

          <Controller
            name="supplierPhone"
            control={control}
            render={({ field }) => (
              <PhoneInput
                variant=""
                country="GB"
                extra="mb-3"
                label="Contact Number"
                placeholder={'Enter the supplier phone number'}
                id="supplierPhone"
                autoComplete="off"
                onChange={(value: number) => { field.onChange(value); }}
                value={field.value}
                error={errors.supplierPhone}
                errorMessage={errors.supplierPhone?.message}
              />
            )}
          />

          <Controller
            name="supplierEmail"
            control={control}
            render={({ field }) => (
              <InputField
                variant=""
                extra="mb-3"
                label="Email"
                placeholder={'Enter a name for this job'}
                id="supplierEmail"
                type="email"
                autoComplete="off"
                onChange={(e: any) => 
                  {
                      try{
                        field.onChange(e);
                      }
                      catch(e){
                        console.log(e);
                      }
                  }}
                value={field.value}
                error={errors.supplierEmail}
                errorMessage={errors.supplierEmail?.message}
              />
            )}
          />
        </div>
        <div className="grid grid-cols-2 grid-rows-1 gap-7">
          <div></div>
          <div className="pl-[55%]">
            <Controller
              name="NextButton"
              control={control}
              render={() => (
                <Button
                  color="clear"
                  type="submit"
                  onClick={() => HandleNextClick('next')}
                  Name="Next"
                  className="h-[40px] w-[100px] text-sm"
                />
              )}
            />
          </div>
        </div>
      </form>
    </div>
  );
};

export default UserInfo;
