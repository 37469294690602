import useAuth from '../hooks/useAuth';
import { FC } from 'react';
import { Navigate } from 'react-router-dom';

const RouteNavigator: FC = () => {
  const { auth } = useAuth();

  //IF statement to get the roles
  if (auth.roles) {
    return <Navigate to="/main/" replace />;
  } else {
    return <Navigate to={"/auth/sign-in/"} replace />;
  }

  //IF statement to get the routes
  // This shouldn't need to be used as an admin will have all roles

};
export default RouteNavigator;