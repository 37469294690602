import { useState } from "react";
import Stepper from "./components/Stepper";
import StepperControl from "./components/StepperControl";
import { UseContextProvider } from "./contexts/StepperContext";
import CustomerInfo from "./components/steps/CustomerInfo";
import Address from "./components/steps/Address";
import Confirmation from "./components/steps/Confirmation";
import Card from "components/card";

const NewUser = () => {
  const [currentStep, setCurrentStep] = useState(1);
  const [FirstStage , setFistStage] = useState<any>({
    firstName: '',
    lastName:'',
    contactNumber:'',
    email:'',
  });
  const [SecondStage , setSecondStage] = useState<any>( {
    address1: '',
    address2: '',
    city: '',
    county: '',
    postcode: ''
  });

  const HandleStepOneData = (data:any) => {
    setFistStage({
      firstName: data.inputs.firstName,
      lastName:data.inputs.lastName,
      contactNumber:data.inputs.contactNumber,
      email:data.inputs.email,
    });
  };

  const handleStepTwoData = (data:any) => {
    setSecondStage({
      address1: data.inputs.address1,
      address2:data.inputs.address2,
      city:data.inputs.city,
      country:data.inputs.country.label,
      postcode:data.inputs.postcode,
    });
  };

  const steps = [
    { stepNo: 1, name: "Customer Info" },
    { stepNo: 2, name: "Address" },
    { stepNo: 3, name: "Confirmation" },
  ];
  const displayStep = (step: {
    stepNo: number;
    name: string;
    highlighted?: boolean;
    selected?: boolean;
    completed?: boolean;
  }) => {
    switch (step.stepNo) {
      case 1:
        return <CustomerInfo 
        sendDataToStepOne={HandleStepOneData}
        handleClick={handleClick}
        currentStep={currentStep}
        steps={steps}/>;
      case 2:
        return <Address 
        sendDataToStepTwo={handleStepTwoData}
        handleClick={handleClick}
        currentStep={currentStep}
        steps={steps}
        />;
      case 3:
        return <Confirmation 
        Data1={FirstStage}
        Data2={SecondStage}
        handleClick={handleClick}
        currentStep={currentStep}
        steps={steps}
        />;
      default:
    }
  };

  const handleClick = (direction: string) => {
    let newStep = currentStep;

    direction === "next" ? newStep++ : newStep--;
    // check if steps are within bounds
    newStep > 0 && newStep <= steps.length && setCurrentStep(newStep);
  };
  return (
    <div className="mt-3 h-full w-full">
      <div className="h-[350px] w-full rounded-md bg-gradient-to-br from-brand-400 to-brand-600 md:h-[390px]" />
      <div className="w-md:2/3 mx-auto h-full w-5/6 md:px-3  3xl:w-7/12">
        <div className="-mt-[280px] w-full pb-10 md:-mt-[240px] md:px-[70px]">
          <Stepper
            action={setCurrentStep}
            steps={steps}
            currentStep={currentStep}
          />
        </div>

        <Card extra={"h-full mx-auto pb-3"}>
          <div className="rounded-[20px]">
            <UseContextProvider>
              {displayStep(steps[currentStep - 1])}
            </UseContextProvider>
          </div>
          {/* navigation button */}

          {/* <StepperControl
            handleClick={handleClick}
            currentStep={currentStep}
            steps={steps}
          /> */}
        </Card>
      </div>
    </div>
  );
};

export default NewUser;
