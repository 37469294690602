import React, { useState  } from "react";
import { useNavigate } from "react-router-dom";
import Button from "components/fields/ButtonField";
import InputField from "components/fields/InputField";

import useAuth from 'hooks/useAuth';
import useAxiosPrivate from 'hooks/useAxiosPrivate';
import BackendRoutes from "api/BackendRoutes/BackendRoutes";
import { toast } from "react-toastify";



const Profile = (props:any) => {
  const {Data1, Data2, handleClick} = props;
  const { auth } = useAuth();
  const  axiosPrivate  = useAxiosPrivate();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  function HandleBackClick(name: any) {
    handleClick(name);
  }

  console.log("Data 1 Info Here: ",Data1 , "Data 2 info here: ", Data2);

  const submitData = async () => { 
    setIsLoading(true);
    try {
      const response = await axiosPrivate.post(BackendRoutes.CreateSupplier + auth.UserID, {
        // Data1
        supplierName: Data1.supplierName,
        supplierCategory: Data1.supplierCategory.value,
        supplierPhone: Data1.supplierPhone,
        supplierEmail: Data1.supplierEmail,
        // Data2
        address1: Data2.address1,
        address2: Data2.address2,
        city: Data2.city,
        country: Data2.country,
        postcode: Data2.postcode,
      })
      if(response.status === 200){
        setIsLoading(false);
        navigate('/main/jobs/view-all');
        toast.success('Job Created Successfully');
      }else{
        setIsLoading(false);
      }
    } catch (error) {
      console.log(error);
      toast.error('Error:' + error);
    }
  };

  
  return (
    <div className="h-full w-full rounded-md px-3 pt-7 md:px-8">
      {/* Header */}
      <h4 className="pt-[5px] text-xl font-bold text-navy-700 dark:text-white">
        Profile
      </h4>
      {/* inputs */}
      <div className="mt-7 grid grid-cols-1 gap-3 md:grid-cols-2">
        <InputField
          label="Supplier Name"
          placeholder={Data1?.supplierName ? Data1?.supplierName : 'No Name for a Supplier was entered'}
          id="name"
          disabled={true}
        />
        <InputField
          label="Supplier Category"
          placeholder={Data1?.supplierCategory?.label ? Data1?.supplierCategory.label : 'No Category Selected'}
          id="name"
          disabled={true}
        />
        <InputField
          label="Phone Number"
          placeholder={Data1?.supplierPhone ? Data1?.supplierPhone : 'No Phone Number'}
          id="name"
          disabled={true}
        />
        <InputField
          label="Email"
          placeholder={Data1?.supplierEmail ? Data1?.supplierEmail : 'No Email'}
          id="name"
          disabled={true}
        />
      </div>

      <div className="mt-10">
        <InputField
          label="Address Line 1"
          placeholder={Data2?.address1 ? Data2?.address1 : 'No Address'}
          id="name"
          disabled={true}
        />
        <div className="mt-3">
        <InputField
          label="Address Line 2"
          placeholder={Data2?.address2 ? Data2?.address2 : 'No Address'}
          id="name"
          disabled={true}
        />
        </div>
      </div>
          <div className="col-span-1 mt-3 grid grid-cols-2 gap-3">
      <InputField
          label="Country"
          placeholder={Data2?.country ? Data2?.country : 'No Country'}
          id="name"
          disabled={true}
        />
        <div className="col-span-1 grid grid-cols-2 gap-3">
        <InputField
          label="City"
          placeholder={Data2?.city ? Data2?.city : 'No City'}
          id="name"
          disabled={true}
        />
        <InputField
          label="Post Code"
          placeholder={Data2?.postcode ? Data2?.postcode : 'No Post Code'}
          id="name"
          disabled={true}
        />
        </div>
        </div>
        <div className="grid grid-cols-2 grid-rows-1 gap-7">
        <div className="pr-[55%] mt-5 ">
              <Button 
              color="clear" 
              type="submit" 
              Name="Previous" 
              onClick={() => HandleBackClick('')}
          />
        </div>
        <div className="pl-[55%] mt-5">

            <Button 
            color="clear" 
            type="submit" 
            Name="Add Supplier" 
            isLoading={isLoading}
            onClick={() => submitData()}
          />
        </div>
      </div>
    </div>
  );
};

export default Profile;
