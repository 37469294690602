import React, { useState, useEffect } from 'react';
import Card from "components/card";
import SwitchField from "components/fields/SwitchField";

function Notification(props:any) {
  const {data, sendBackData} = props;

  const [Auto, setAuto] = useState( true )
  const [AddedToJob, setAddedToJob] = useState( true )
  const [StatusChange, setStatusChange] = useState( true )

  const handleChange = (e: any) => {  setAuto(e.target.checked); SendToBack();  };

  const handleChange2 = (e: any) => {  setAddedToJob(e.target.checked); SendToBack();  };

  const handleChange3 = (e: any) => {  setStatusChange(e.target.checked); SendToBack();  };

  const SendToBack = () => {
    sendBackData({supplierAutoMatch: Auto, AddedToJob: AddedToJob, StatusChange: StatusChange})
  }


  return (
    <Card extra={"w-full h-auto mt-4 p-3"}>
      <div className="relative mb-2 flex items-center justify-between pt-1">
        <h4 className="text-xl px-2 font-bold text-navy-700 dark:text-white">
          Notifications & Actions
        </h4>
        {/* <CardMenu /> */}
      </div>
        <p className=" mb-2 px-2 text-base text-gray-600">
          Chose which email you want to be sent to you.
         </p>
      <div className="flex flex-col px-2">
        {/* the custom checkbox desing added in src/index.js */}

      <SwitchField
        id="auto"
        label="Auto Match"
        desc="Allow auto match of the supplier with the invoice and bank statement"
        mt="mt-3"
        mb="mb-0"
        onChange={() => handleChange}
        checked={data.supplierAutoMatch ? data.supplierAutoMatch : Auto}
      />

      <SwitchField
        id="AddedToJob"
        label="Added to a Job"
        desc="You will be notified when this supplier is added to a job."
        mt="mt-3"
        mb="mb-0"
        onChange={handleChange2}
        checked={AddedToJob}
      />

      <SwitchField
        id="StatusChange"
        label="Status Change"
        desc="You will be notified if the status of this supplier changes."
        mt="mt-3"
        mb="mb-0"
        onChange={handleChange3}
        checked={StatusChange}
      />   
      
      </div>
    </Card>
  );
}

export default Notification;
