import React, { useState } from "react";
import Card from "components/card";
import TextField from "components/fields/TextField";

const Notes = (props:any) => {
  const {data, sendBackData} = props;
  const [notes, setNotes] = useState<string>("");


  return (
    <Card extra={"w-full p-4 flex flex-col"}>
      <div className="w-full">
        <p className="text-xl font-bold text-navy-700 dark:text-white">
          Notes 
        </p>
        <p className="mt-2 text-base text-gray-600">
          Add notes of the supplier. These will be saved atomically and can be viewed at any time.
        </p>
      </div>
      {/* Project 1 */}
      {/* <div className="relative flex-grow top-2 bottom-2 "> */}
        <TextField
          id={"supplierNotes"}
          onChange={(e:any) => {setNotes(e.target.value)}}
          onBlur={() => sendBackData({ inputs: notes })}
          cols={50}
          rows={10}
          placeholder={data.supplierNotes ? data.supplierNotes : "Enter notes here..."}
        />
      {/* </div> */}
    </Card>
  );
};

export default Notes;
