import { Routes, Route } from "react-router-dom";

// import RTLLayout from "layouts/rtl";
import AdminLayout from "layouts/main";
import AuthLayout from "layouts/auth";
import Missing from "layouts/404";
import "assets/css/Plugins.css";
import { useState, useEffect } from "react";

import PersistLogin from "api/auth/PersistLogin";
import RequireAuth from "api/auth/RequireAuth";
import RouteNavigator from "context/routeNavigator";
import {  ROLES } from "context/Roles";

const App = () => {
  // Create a new context
  const [themeApp, setThemeApp] = useState<any>({
    "--background-100": "#FAFCFE",
    "--background-900": "#070f2e",
    "--shadow-100": "rgba(112, 144, 176, 0.08)",
    "--color-50": "#E9E3FF",
    "--color-100": "#C0B8FE",
    "--color-200": "#A195FD",
    "--color-300": "#8171FC",
    "--color-400": "#7551FF",
    "--color-500": "#422AFB",
    "--color-600": "#3311DB",
    "--color-700": "#2111A5",
    "--color-800": "#190793",
    "--color-900": "#11047A",
  });
  const [mini, setMini] = useState(false);

  // When the theme state changes, this effect will update the CSS variables in the document's root element
  useEffect(() => {
    let color;
    for (color in themeApp) {
      document.documentElement.style.setProperty(color, themeApp[color]);
    }

    // const darkmode = localStorage.getItem("darkmode");

    if (localStorage.getItem("darkmode") === "true") {  document.body.classList.add("dark");} 
    else {  document.body.classList.remove("dark"); }


    //eslint-disable-next-line
  }, [themeApp]);

  return (
    <Routes>
      <Route element={<PersistLogin />}>  

        <Route path="/" element={<RouteNavigator />} />

        <Route path="auth/*" element={<AuthLayout />} />
      
        <Route element={<RequireAuth allowedRoles={[ROLES.Admin, ROLES.Supervisor, ROLES.User]} />}>
          <Route
            path="main/*"
            element={
              <AdminLayout
                setMini={setMini}
                mini={mini}
                theme={themeApp}
                setTheme={setThemeApp}
              />
            }
          />
        </Route>

        {/* Catch all */}
        <Route path="*" element={<Missing />} />

      
      </Route>

    </Routes>
  );
};

export default App;
