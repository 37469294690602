function InputField(props: {
  id: string;
  label?: string;
  extra?: string;
  extra2?: string;
  placeholder: string;
  state?: string;
  disabled?: boolean;
  cols: number;
  rows: number;
  onChange?: any;
  onBlur?: any;
}) {
  const {
    label,
    id,
    extra,
    extra2,
    placeholder,
    cols,
    rows,
    state,
    disabled,
    onChange,
    onBlur,
  } = props;

  return (
    <div className={`${extra}`}>
      {label && (
        <label
          htmlFor={id}
          className="mb-4 ml-3 text-sm font-bold text-navy-700 dark:text-white"
        >
          {label}
        </label>
      )}
      <div>
        <textarea
          onChange={onChange}
          onBlur={onBlur}
          cols={cols}
          rows={rows}
          placeholder={placeholder}
          className={`${extra2} mt-2 relative  flex-grow w-full hover:border-gray-400 focus:border-blue-500 focus:ring focus:ring-blue-500/20 items-center justify-center rounded-md border bg-white/0 p-3 text-sm outline-none ${
            disabled === true
              ? "!border-none !bg-gray-100 dark:!bg-white/5 dark:placeholder:!text-[rgba(255,255,255,0.15)]"
              : state === "error"
              ? "border-red-500 text-red-500 placeholder:text-red-500 dark:!border-red-400 dark:!text-red-400 dark:placeholder:!text-red-400"
              : state === "success"
              ? "border-green-500 text-green-500 placeholder:text-green-500 dark:!border-green-400 dark:!text-green-400 dark:placeholder:!text-green-400"
              : "border-gray-200 dark:!border-white/10 dark:text-white"
          } `}

          id={id}
        />
      </div>
    </div>
  );
}

export default InputField;
