import Card from "components/card";
import React from "react";
import ReactApexChart from "react-apexcharts";
import "react-circular-progressbar/dist/styles.css";
import { pieChartOptionsChartsJobs } from 'variables/charts';



function Conversion(props:any) {

  const {StatsData} = props;

  const active = StatsData[0]?.StartedNumber ? StatsData[0]?.StartedNumber : 0;
  const nonActive = StatsData[0]?.PendingNumber + StatsData[0].CompletedNumber ? StatsData[0]?.PendingNumber + StatsData[0].CompletedNumber : 0;

  let numericValues = [];

  if (StatsData[0] && StatsData[0].EstCost) {
    const stringArray = StatsData[0].EstCost;
  
    numericValues = stringArray.map((str: string) => {
      const numericString = str.replace(/[^0-9.-]+/g, ""); // Remove non-numeric characters
      const numericValue = parseFloat(numericString); // Convert the string to a number
      return isNaN(numericValue) ? 0 : numericValue; // If the result is NaN, return 0 instead
    });
  }
  
  const total = numericValues.reduce((sum: any, value: any) => sum + value, 0);
  const formattedTotal = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'GBP' }).format(total);


  const chartSeries:any = [  active,  nonActive  ];

  return (
    <Card extra={"w-full py-4 px-8"}>
      <div className="mt-2 mb-2 flex flex-col px-4 text-center">
        <p className="text-lg font-bold text-navy-700 dark:text-white">
          Statistics 
        </p>
        <p className="mt-2 px-4 text-sm font-medium text-gray-600">
          See how many active to non active jobs are in your company as well as potential revenue.
        </p>
      </div>
      {/* Circular progressbar */}
      {/* <div className="mx-auto mt-3 flex h-[140px] w-[full] items-center justify-center"> */}

    { (!StatsData || !active || !nonActive ) ? (
    <div className="flex items-center justify-center w-full h-full">
      <p className="text-lg font-bold text-gray-400">
        No data available please check back later
      </p>
    </div>
  ) : ( 
      <>
      <ReactApexChart
            options={pieChartOptionsChartsJobs}
            series={chartSeries}
            type="pie"
            width="100%"
            height="100%" /><div className="mt-2 flex h-fit w-full items-center justify-center rounded-md border-[1px] border-gray-200 bg-white bg-clip-border px-4 py-4 shadow-md shadow-[#F3F3F3] dark:border-[#ffffff33] dark:!bg-navy-800 dark:text-white dark:shadow-none">
              <div className="flex flex-col items-center justify-center">
                <div className="flex items-center justify-center">
                  <p className="ml-1 text-xs font-normal text-gray-600">All total Jobs </p>
                </div>
                <p className="mt-px text-xl font-bold text-navy-700 dark:text-white">
                  {StatsData[0]?.TotalNumber ? StatsData[0]?.TotalNumber : 0}
                </p>
              </div>
              <div className="mx-8 h-11 w-px bg-gray-400 dark:!bg-white/20" />
              <div className="flex flex-col items-center justify-center">
                <div className="flex items-center justify-center">
                  <p className="ml-1 text-xs font-normal text-gray-600">
                    Est. Revenue
                  </p>
                </div>
                <p className="mt-px text-xl font-bold text-navy-700 dark:text-white">
                  {formattedTotal}
                </p>
              </div>
            </div></>
  )}
    </Card>
  );
}

export default Conversion;
