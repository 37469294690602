import { AxiosResponse } from 'axios';
import axios from '../api/axios';
import useAuth from './useAuth';
import BackendRoutes from '../api/BackendRoutes/BackendRoutes';
import secureLocalStorage from 'react-secure-storage';

interface Auth {
  UserID?: string;
  roles?: string[];
  accessToken?: string;
  setAuth?: any;
}

const useRefreshToken = () => {
  const  { setAuth }  = useAuth() as any;

  const refresh = async (): Promise<string> => {
    const response: AxiosResponse = await axios.get(BackendRoutes.refreshToken, {
      withCredentials: true
    });
    setAuth((prev: Auth) => {
      return {
        ...prev,
        UserID: response.data.currentID,
        roles: response.data.roles,
        accessToken: response.data.accessToken
      }
    });
    secureLocalStorage.setItem('compName', response.data.CompanyName);
    secureLocalStorage.setItem('Name', response.data.firstName);
    secureLocalStorage.setItem('ProfilePic', response.data.ProfilePic);
    return response.data.accessToken;
  }
  return refresh;
};

export default useRefreshToken;