/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState } from 'react';

import { IoMdNotificationsOutline } from 'react-icons/io';
import { BsArrowBarUp } from "react-icons/bs";
import { MdDelete } from "react-icons/md";

import { Badge } from "@material-tailwind/react";
import Dropdown from "components/dropdown";
import BarLoader from "react-spinners/BarLoader";
import InfiniteScroll from 'react-infinite-scroll-component';
import { toast } from 'react-toastify';
import dayjs from 'dayjs';

import useAuth from 'hooks/useAuth';
import axios from 'api/axios';
import backendRoutes from 'api/BackendRoutes/BackendRoutes';


function Notification() {
    const { auth } = useAuth() as any;
    const [dataSource, setDataSource] = useState<any>([]);
    const [hasMore, setHasMore] = useState(true);

    useEffect(() => {
        fetchMore();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const fetchMore = async () => {
        const userID = auth.UserID;
        const skipValue = dataSource.length;
    
        try {
          //This will fetch the data from the server
          if(hasMore === false) {
            return
            } else {
                const response = await axios.get(backendRoutes.GetNotifications + userID + '/' + skipValue,
                {
                  headers: { 'Content-Type': 'application/json' },
                  withCredentials: true,
                  params: { userID },
                }
              )
            setDataSource([...dataSource, ...response.data.message])
            setHasMore(response?.data?.HasMore)
          }
        } catch (error:any) {
        if (!error?.response) {
            toast.error('No Server Response - Please Try Again Later');
        } else if (error.response?.status === 412) {
          toast.error('The Notification could not be found');
        } else if (error.response?.status === 400) {
          toast.error('There are no notifications to delete');
        } else {
          toast.error('System Error - Please Try Again Later');
        }
      };
    };

    const handleClear = async (item:any) => {
        const itemToDelete = dataSource[item]._id;
        //try catch to delete a single notification
        try {
          const deleteResponse = await axios.delete(backendRoutes.DeleteSingleNotification + itemToDelete,{
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true,
          })
          dataSource.splice(item, 1);
          setDataSource([...dataSource]);
        } catch (error:any) {
          if (!error?.deleteResponse) {
            toast.error('No Server Response - Please Try Again Later');
        } else if (error.deleteResponse?.status === 412) {
          toast.error('The Notification could not be found for the current user');
        } else if (error.deleteResponse?.status === 400) {
          toast.error('There are no notifications to show for the user');
        } else {
          toast.error('System Error - Please Try Again Later');
        }
        }
    };

    const handleAllClear = async () => {
        const userID = auth.UserID;
        //try catch to delete all notifications
        try {
          const deleteResponse = axios.delete(backendRoutes.DeleteAllNotifications + userID,{
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true,
          })
          setDataSource([]);
        }
        catch (error:any) {
          if (!error?.deleteResponse) {
            toast.error('No Server Response - Please Try Again Later');
        } else if (error.deleteResponse?.status === 412) {
          toast.error('The Notification could not be found');
        } else if (error.deleteResponse?.status === 400) {
          toast.error('There are no notifications to delete');
        } else {
          toast.error('System Error - Please Try Again Later');
        }
        }
    };

      return (
        <div>
            <Dropdown
            button={
              <p className="cursor-pointer">
                <Badge
                  color="red"
                  invisible={dataSource.length === 0}
                  content={dataSource.length > 50 ? "50+" : dataSource.length}
                  // className="absolute text-sm top-0 right-0 -mt-1 -mr-1"
                >
                    <IoMdNotificationsOutline className="h-6 w-6 text-gray-600 dark:text-white" />
                </Badge>
              </p>
            }
            animation="origin-[65%_0%] md:origin-top-right transition-all duration-300 ease-in-out"
            children={
              <div className="flex w-[360px] h-[450px] flex-col gap-3 rounded-[20px] bg-white p-4 shadow-xl shadow-shadow-500 dark:!bg-navy-700 dark:text-white dark:shadow-none sm:w-[460px] sm:h-[450px] ">
                <div className="flex items-center justify-between">
                  <p className="text-base font-bold text-navy-700 dark:text-white">
                    Notification
                  </p>
                  <p className="text-sm font-bold text-navy-700 dark:text-white">
                  
                    <button onClick={handleAllClear}>Mark all read</button>
                  </p>
                </div>
                <div id='scrollableDiv' className="flex flex-col gap-2 h-[450px] overflow-y-auto">
                  <InfiniteScroll
                    dataLength={dataSource.length}
                    next={fetchMore}
                    hasMore={hasMore}
                    loader={
                      <BarLoader
                        color="#2111A5"
                        height={5}
                        width={420}
                      />}
                    endMessage={<p className='font-semibold text-center pt-2 pb-2'>No More Notifications</p>}
                    style={{ padding: '2px'}}
                    initialScrollY={50}
                    scrollableTarget="scrollableDiv">
                    {dataSource?.map((item:any, index:any) => (
                      <>
                      <button 
                      onClick={() => handleClear(index)}
                      className="flex w-full items-center mt-2 ">
                        <div className="flex h-full w-[85px] items-center justify-center rounded-md bg-gradient-to-b from-brand-400 to-brand-500 py-4 text-2xl text-white">
                        <div className="relative hover:opacity-0">
                          <BsArrowBarUp className="absolute"/>
                          <div className="opacity-0 hover:opacity-100 text-white">
                            <MdDelete/>
                          </div>
                        </div>
                        </div>

                        <div className="ml-2 flex h-full w-full flex-col justify-center rounded-lg px-1 text-sm">
                          <p className="mb-1 text-left text-base font-bold text-gray-900 dark:text-white">
                            {item.Notification}
                          </p>
                          <p className="font-base text-left text-xs text-gray-900 dark:text-white">
                          { dayjs(item.CreatedAt).format('DD MMM YYYY - HH:mm:ss') }
                          </p>
                        </div>
                      </button>
                      </>
                    ))}
                  </InfiniteScroll>
                </div>
              </div>
            }
            classNames={'py-2 pb-2 top-4 -left-[230px] md:-left-[440px] w-max'}
          />
        </div>
      )
}

export default Notification;