import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import Card from 'components/card';
import ButtonField from 'components/fields/ButtonField';
import DropDown from 'components/fields/DropDownField';
import { MdDelete } from 'react-icons/md';
import { AiFillFileAdd } from 'react-icons/ai';
import { useForm, Controller } from 'react-hook-form';

const Suppliers = (props: any) => {
  const { UpdateData, backendData } = props;

  const { control, handleSubmit } = useForm();

  const dropdownData = useMemo(
    () => backendData?.Dropdown || [],
    [backendData.Dropdown]
  );
  const supplierData = useMemo(
    () => backendData?.Supplier || [],
    [backendData.Supplier]
  );

  console.log('Supplier Data', supplierData);

  const onSubmit = (data: any) => {
    UpdateData(data.Supplier);
  };

  const MapOptions = dropdownData
    ? dropdownData.map((item: any) => {
        return {
          label: item.supplierName,
          value: item._id,
        };
      })
    : [];

  return (
    <Card extra={'w-full h-full p-3'}>
      {/* Header */}
      <div className="mb-2 mt-2 w-full">
        <h4 className="px-2 text-xl font-bold text-navy-700 dark:text-white">
          Suppliers
        </h4>
        <p className="mt-2 px-2 text-base text-gray-600">
          You can view and add suppliers to your job.
        </p>
      </div>

      <div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="grid flex-col sm:grid-cols-1 md:grid-cols-3 grid-rows-1 gap-1">
            <div className="col-span-2">
              <Controller
                name="Supplier"
                control={control}
                render={({ field }) => (
                  <DropDown
                    variant=""
                    extra="mb-3"
                    isMulti={true}
                    placeholder={'Add a supplier'}
                    id="Supplier"
                    onChange={field.onChange}
                    isSearchable={true}
                    value={field.value}
                    options={MapOptions}
                  />
                )}
              />
            </div>
            <div className="col-start-3 relative flex items-end justify-end  sm:w-full md:w-auto md:bottom-3">
              <ButtonField
                Name={<AiFillFileAdd />}
                type="submit"
                color="clear"
                extra="h-[70%]"
                id="addSupplier"
                isLoading={false}
              />
            </div>
          </div>
        </form>
      </div>

      <div className="h-[20rem] overflow-auto flex flex-col items-start justify-center rounded-md border-[1px] border-gray-200 bg-white bg-clip-border px-3 py-4 dark:border-white/20 dark:!bg-navy-700 ">
        <div className="w-full h-full">
          <div className="flex flex-col gap-2 w-full h-full">
            {/* Cards */}

            {!supplierData || supplierData.length === 0 ? (
              <div className="flex items-center justify-center w-full h-full">
                <p className="text-lg font-bold text-gray-400">
                  No data available please check back later
                </p>
              </div>
            ) : (
              <>
                {supplierData?.map((item: any, index: number) => {
                  return (
                    <div className="flex flex-col border p-2 rounded-md ">
                      <div className="grid grid-cols-3 grid-rows-1 gap-2 w-full">
                        <div className="col-span-2">
                          <Link to={`/main/supplier/view-supplier/${item._id}`} >
                            <button className="text-start" >
                          <h4 className="text-md font-semibold text-navy-700 dark:text-white text-wrap">
                            {item.supplierName}
                          </h4>
                          <p className="text-sm text-gray-600 dark:text-gray-400">
                            {item.supplierPhone
                              ? item.supplierPhone
                              : item.supplierEmail}
                          </p>
                            
                            </button>
                            </Link>
                          
                        </div>
                        <div className="flex justify-end items-end">
                          <ButtonField
                            Name={<MdDelete />}
                            type="button"
                            color="clear"
                            id="viewSupplier"
                            extra={
                              'w-[30%] bg-red-500 hover:bg-red-600 active:bg-red-700 dark:bg-red-400 dark:hover:bg-red-300 dark:active:bg-red-200'
                            }
                            isLoading={false}
                            onClick={() => {
                              console.log('View Supplier');
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  );
                })}
              </>
            )}
          </div>
        </div>
      </div>
    </Card>
  );
};

export default Suppliers;
