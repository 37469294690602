import Card from "components/card";

const General = () => {
  return (
    <Card extra={"w-full h-full p-3"}>
      {/* Header */}
      <div className="mb-8 mt-2 w-full">
        <h4 className="px-2 text-xl font-bold text-navy-700 dark:text-white">
          Time Off
        </h4>
        <p className="mt-2 px-2 text-base text-gray-600">
          View your time off balance and request time off.
        </p>
      </div>
      {/* Cards */}
      <div className="grid grid-cols-2 gap-4 px-2">
        <div className="flex flex-col items-start justify-center rounded-md border-[1px] border-gray-200 bg-white bg-clip-border px-3 py-4 dark:border-white/20 dark:!bg-navy-700">
          <p className="text-sm text-gray-600">Education</p>
          <p className="text-base font-medium text-navy-700 dark:text-white">
            Stanford University
          </p>
        </div>

        <div className="flex flex-col justify-center rounded-md border-[1px] border-gray-200 bg-white  bg-clip-border px-3 py-4 dark:border-white/20 dark:!bg-navy-700">
          <p className="text-sm text-gray-600">Languages</p>
          <p className="text-base font-medium text-navy-700 dark:text-white">
            English, Spanish, Italian
          </p>
        </div>

        <div className="flex flex-col items-start justify-center rounded-md border-[1px] border-gray-200 bg-white bg-clip-border px-3 py-4 dark:border-white/20 dark:!bg-navy-700">
          <p className="text-sm text-gray-600">Department</p>
          <p className="text-base font-medium text-navy-700 dark:text-white">
            Product Design
          </p>
        </div>

        <div className="flex flex-col justify-center rounded-md border-[1px] border-gray-200 bg-white bg-clip-border px-3 py-4 dark:border-white/20 dark:!bg-navy-700">
          <p className="text-sm text-gray-600">Work History</p>
          <p className="text-base font-medium text-navy-700 dark:text-white">
            English, Spanish, Italian
          </p>
        </div>

        <div className="flex flex-col items-start justify-center rounded-md border-[1px] border-gray-200 bg-white bg-clip-border px-3 py-4 dark:border-white/20 dark:!bg-navy-700">
          <p className="text-sm text-gray-600">Organization</p>
          <p className="text-base font-medium text-navy-700 dark:text-white">
            Simmmple Web LLC
          </p>
        </div>

        <div className="flex flex-col justify-center rounded-md border-[1px] border-gray-200 bg-white bg-clip-border px-3 py-4 dark:border-white/20 dark:!bg-navy-700">
          <p className="text-sm text-gray-600">Birthday</p>
          <p className="text-base font-medium text-navy-700 dark:text-white">
            20 July 1986
          </p>
        </div>
      </div>
    </Card>
  );
};

export default General;
