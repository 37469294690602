import { useContext, useDebugValue } from "react";
import AuthContext from "../context/AuthProvider";

interface Auth {
  roles: any;
  user?: any; 
  accessToken?: string;
  AuthContext?: any;
  persist: boolean;
  UserID: string;
  pwd:string,
  UserFirstName?: string;
}

const useAuth = (): { auth: Auth } => {
  const { auth } = useContext(AuthContext);
  useDebugValue(auth, (auth: Auth) => auth?.user ? "Logged In" : "Logged Out");
  return useContext(AuthContext);

}

export default useAuth;