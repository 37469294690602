import { Input, InputGroup, InputRightElement, Button } from '@chakra-ui/react'
import React from 'react';
import { AiFillEye, AiFillEyeInvisible } from 'react-icons/ai';

// Custom components
function InputPasswordField(props: {
  id: string;
  label: string;
  extra?: string;
  placeholder: string;
  variant?: string;
  state?: string;
  disabled?: boolean;
  onChange?: any;
  value?: any;
  error?: any;
  errorMessage?: any;
}) {
  const {
    label,
    id,
    extra,
    placeholder,
    variant,
    state,
    disabled,
    onChange,
    value,
    error,
    errorMessage,
  } = props;
  
  const [show, setShow] = React.useState(false)
  const handleClick = () => setShow(!show)

  return (
    <div className={`${extra}`}>
      <label
        htmlFor={id}
        className={`text-sm text-navy-700 dark:text-white ${
          variant === "auth" ? "ml-1.5 font-medium" : "ml-3 font-bold"
        }`}
      >
        {label}
      </label>
      <InputGroup size='md'>
                    <Input
                      pr='4rem'
                      type={show ? 'text' : 'password'}
                      placeholder={placeholder}
                      onChange={onChange}
                      value={value}
                      disabled={disabled}
                      id={id}
                      className={`mt-2 flex h-12 w-full items-center justify-center hover:border-gray-400 focus:border-blue-500 focus:ring focus:ring-blue-500/20 rounded-md border bg-white/0 p-3 text-sm outline-none ${
                        disabled === true
                          ? "!border-none !bg-gray-100 dark:!bg-white/5 dark:placeholder:!text-[rgba(255,255,255,0.15)]"
                          : state === "error"
                          ? "border-red-500 text-red-500 placeholder:text-red-500 dark:!border-red-400 dark:!text-red-400 dark:placeholder:!text-red-400"
                          : state === "success"
                          ? "border-green-500 text-green-500 placeholder:text-green-500 dark:!border-green-400 dark:!text-green-400 dark:placeholder:!text-green-400"
                          : "border-gray-200 dark:!border-white/10 dark:text-white"
                      }`}
                    />
                    <InputRightElement width='3rem' height='4rem'>
                      <Button colorScheme='clear' variant="ghost" onClick={handleClick}>
                        {show ? <AiFillEyeInvisible /> : <AiFillEye /> }
                      </Button>
                    </InputRightElement>
                  </InputGroup>
                  {error && (
          <span className="text-red-500 text-sm">
            {(errorMessage) as string}
          </span>
      )}
    </div>
  );
}

export default InputPasswordField;
