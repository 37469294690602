import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import Banner from "./components/Banner";
import Suppliers from "./components/Suppliers";
import Notification from "./components/Notification";
import Note from "./components/Notes";
import Edit from "./components/Edit";

import { toast } from 'react-toastify';
import { Helmet } from 'react-helmet';

import useAxiosPrivate from 'hooks/useAxiosPrivate';
import BackendRoutes from "api/BackendRoutes/BackendRoutes";
import useAuth from "hooks/useAuth";


const ProfileOverview = () => {
  const { id } = useParams()
  const { auth } = useAuth() as any;
  const  axiosPrivate  = useAxiosPrivate();
  
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [Data, setData] = useState<any>([]);
  const [JobData , setJobData] = useState<any>([]);
  const [updateDate, setUpdateDate] = useState<any>({});
  const [Status, setStatus] = useState<any>([]);
  const [NotificationCenter, setNotificationCenter] = useState<any>([]);
  const [Notes , setNotes] = useState<any>([])

  const GetData = async () => { // Get Data from backend
    try {
      const response = await axiosPrivate.get(BackendRoutes.GetSelectedSupplier + id);
      setData(response.data);

      const JobResponse = await axiosPrivate.get(BackendRoutes.GetJobData + auth.UserID);
      setJobData(JobResponse.data);

    } catch (error:any) {
      toast.error(error.response.data.message);
    }

  }

  const UpdateData = async () => { // Update Data
    try {
      const response = await axiosPrivate.post(BackendRoutes.EditSelectedSupplier + id, {
        //Supplier Details
        SupplierName: updateDate?.inputs?.supplierName,
        supplierCategory: updateDate?.inputs?.supplierCategory?.value,
        supplierEmail: updateDate?.inputs?.supplierEmail,
        supplierPhone: updateDate?.inputs?.supplierPhone,

        //Status of the supplier if they are active or not.
        currentStatus: Status.inputs?.value,

        // Address
        AddressLineOne: updateDate?.inputs?.AddressLineOne,
        address2: updateDate?.inputs?.address2,
        Country: updateDate?.inputs?.country?.value,
        City: updateDate?.inputs?.City,
        postcode: updateDate?.inputs?.Postcode,

        // Notes
        supplierNotes: Notes.inputs

      })
      if(response.status === 200){
        toast.success(response.data.message);
      }
    } catch (error:any) {
      toast.error('Error Updating the Supplier! ', error.response.data.message);
    }
  }

  // const UpdateNotification = async () => { // Update Notification and action
  //   try {
  //     const response = await axiosPrivate.post(BackendRoutes.UpdateNotification + id, {
  //       supplierAutoMatch: NotificationCenter.supplierAutoMatch,
  //       AddedToJob: NotificationCenter.AddedToJob,
  //       StatusChange: NotificationCenter.StatusChange
  //     })
  //     if(response.status === 200){
  //       toast.success(response.data.message);
  //     }
  //   } catch (error:any) {
  //     toast.error('Error Updating the Supplier! ', error.response.data.message);
  //   }

  // };

  const HandleJobData = (data:any) => {
      setUpdateDate(data);
    setIsLoading(true);
  }

  const HandleStatusChange = (data:any) => {
        setStatus(data);
    setIsLoading(true);
  }

  const HandleNotificationChanges = (data:any) => {
      setNotificationCenter(data);
      setIsLoading(true);
    }

  const HandleNotesChanges = (data:any) => {
        setNotes(data);
    setIsLoading(true);
  }


  useEffect(() => { // Update Normal Data
    if(isLoading === false) return;  
    UpdateData();
    setIsLoading(false);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading])

  // useEffect(() => { // Update Notification and action data
  //   UpdateNotification();
  //   setIsLoading(false);
  // // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [isLoading])

  useEffect(() => { // Get Data
    GetData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  return (
    <div className="flex w-full flex-col gap-5 lg:gap-5">
      <Helmet>
        <title> Edit {Data.supplierName ? Data.supplierName : 'Current'} Details</title>
        
      </Helmet>
      <div className="w-ful mt-3 flex h-fit flex-col gap-5 lg:grid lg:grid-cols-12">
        <div className="col-span-4 lg:!mb-0">
          <Banner
            data={Data}
            // customer={customerInfo}
            sendBackData={HandleStatusChange} 
          />
          <Notification
            data={Data}
            sendBackData={HandleNotificationChanges}  
          />
        </div>
        <div className="z-5 col-span-8 lg:!mb-0">
          <Edit 
            data={Data}
            sendBackData={HandleJobData}
            />
        </div>

      </div>


      {/* all Project & ... */}
      <div className="mb-4 grid h-full grid-cols-1 gap-5 lg:!grid-cols-12">
        <div className="col-span-5 lg:col-span-6 lg:mb-0 3xl:col-span-4">
          <Suppliers 
          data={JobData}
          />  
        </div>
        <div className="col-span-5 lg:col-span-6 lg:mb-0 3xl:col-span-8">
          <Note 
          data={Data}
          sendBackData={HandleNotesChanges}
          />
        </div>
      </div>
    </div>
  );
};

export default ProfileOverview;
