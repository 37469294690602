import { Outlet } from 'react-router-dom';
import { useState, useEffect } from 'react';
import useRefreshToken from '../../hooks/useRefreshToken';
import useAuth from '../../hooks/useAuth';
import BarLoader from "react-spinners/BarLoader";

interface Auth {
  accessToken?: string;
  // Add other properties of 'auth' here
}

const PersistLogin: React.FC = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const refresh = useRefreshToken();
  const { auth, persist } = useAuth() as any as { auth: Auth; persist: boolean }

  useEffect(() => {
    let isMounted = true;
    const verifyRefreshToken = async (): Promise<void> => {
      try {
        await refresh();
      } catch (err: any) { // Replace 'any' with the type of your error object
        console.error(err);
      } finally {
        isMounted && setIsLoading(false);
      }
    };

    !auth?.accessToken && persist ? verifyRefreshToken() : setIsLoading(false);

    return () => { isMounted = false; };
  }, [auth, persist, refresh]);
  
  useEffect(() => {}, [isLoading]);

  return (
    <>
      {!persist ? (
        <Outlet />
      ) : isLoading ? (
        <div role="status" className='w-screen flex items-center justify-center'>
        <BarLoader
          color="#2111A5"
          height={4}
          width={1000000}
          loading={isLoading}
          />
        </div>
      ) : (
        <Outlet />
      )}
    </>
  );
};

export default PersistLogin;

