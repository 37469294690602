
import React, { useState , useEffect , useMemo } from "react";
import { RiMoneyPoundBoxFill } from "react-icons/ri";
import Card from "components/card";
import Transaction from "components/dataDisplay/Transaction";
import ReactApexChart from "react-apexcharts";
import "react-circular-progressbar/dist/styles.css";
import { barChartOptionsBalance } from 'variables/charts';

function Balance(props: any) {
  const { balance } = props;

  const datain = useMemo(() => (balance ? balance.moneyInByMonth : []), [balance]);
  const dataout = useMemo(() => (balance ? balance.moneyOutByMonth : []), [balance]);
  const [TotalMIncome, setTotalMIncome] = useState(0);
  const [TotalExpense, setTotalExpense] = useState(0);

  const TotalIncome = () => {
    if (!datain) {
      return;
    }
    const MoneyInTotalSum = datain.reduce((accumulator: number, item: any) => {
      const balanceString = String(item.totalForMonth);
      const numericValue = parseFloat(balanceString.replace(/[^0-9.-]+/g, ""));
      return !isNaN(numericValue) && typeof numericValue === 'number' ? accumulator + numericValue : accumulator;
    }, 0);
      
      setTotalMIncome(Math.round(MoneyInTotalSum));
  }

  const TotalOutcome = () => {
    if (!dataout) {  return; }
    const MoneyInTotalSum = dataout.reduce((accumulator: number, item: any) => {
      const balanceString = String(item.totalForMonth);
      const numericValue = parseFloat(balanceString.replace(/[^0-9.-]+/g, ""));
      return !isNaN(numericValue) && typeof numericValue === 'number' ? accumulator + numericValue : accumulator;
    }, 0);
    
    setTotalExpense(Math.round(MoneyInTotalSum));
  }

  const CurrentBalance:any = TotalMIncome - TotalExpense;

  useEffect(() => {
    TotalIncome();
    TotalOutcome();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataout, datain]);

  const chartSeries:any = [{
    
    data: [{
      x: 'Money In',
      y: [TotalMIncome]
    }, {
      x: 'Money Out',
      y: [TotalExpense]
    }]
  }
  ]



  return (
    <Card extra={"p-4 h-full"}>
      <div
        className={`bg-image relative mt-[7px] flex h-fit w-full justify-between rounded-md bg-brand-900 bg-ballanceDashboard bg-cover bg-right px-3 py-4 text-white dark:bg-navy-700`}
      >
        <div>
          <p className="text-sm font-medium">
            Current Total Balance
          </p>
          <h5 className="text-[34px] !font-bold">
          { 
            CurrentBalance 
              ? CurrentBalance.toLocaleString('en-US', { style: 'currency', currency: 'GBP' }) 
              : "No data available"
          }

          </h5>
        </div>
      </div>
      <p className="mt-[19px] text-sm font-medium text-gray-600">Breakdown</p>



      {/* Bill section */}

      
    { (!TotalMIncome || !TotalExpense) ? (
    <div className="flex items-center justify-center text-center w-full h-full">
      <p className="text-lg font-bold text-gray-400">
        No data available please check back later
      </p>
    </div>
  ) : (
    <div className="mt-[10%]">
      <Transaction
        title="Money in"
        date=""
        sum={TotalMIncome.toLocaleString('en-US', { style: 'currency', currency: 'GBP' })}
        mb="mb-[20px]"
        icon={
          <RiMoneyPoundBoxFill className="text-green-400 dark:text-green-300" />
        }
      />
      <Transaction
        title="Money out"
        date=""
        sum={` - ${TotalExpense.toLocaleString('en-US', { style: 'currency', currency: 'GBP' })}`}
        mb="mb-[20px]"
        icon={<RiMoneyPoundBoxFill className="text-red-400 dark:text-red-300" />}
      />
      <ReactApexChart
        options={barChartOptionsBalance}
        series={chartSeries}
        type="bar"
        width="100%"
        height="100%"
      />
    </div>
  )
  }
    </Card>
  );
}

export default Balance;
