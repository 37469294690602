import {useState} from "react";
import banner from "assets/img/profile/banner.png";
import Card from "components/card";

import DropDown from 'components/fields/DropDownField';

const Banner = (props:any) => {
  const {data, sendBackData} = props;

  const [selectedValue, setSelectedValue] = useState<string | null>(null);

  const JobTypes = [
    { value: 'true', label: 'Active Account' },
    { value: 'false', label: 'Non Active Account' },
  ];

  const onSubmit = (value:any) => {
    setSelectedValue(value);
    sendBackData({ inputs: value });
  }

  return (
    <Card extra={"items-center w-full h-auto p-[16px] bg-cover"}>
      {/* Background and profile */}
      <div
        className="relative mt-1 flex h-32 w-full justify-center rounded-md bg-cover"
        style={{ backgroundImage: `url(${banner})` }}
      >
      </div>
      {/* Name and position */}
        <div className="mt-2 flex flex-col items-center">
          <h4 className="text-3xl font-bold text-navy-700 dark:text-white">
            {data?.supplierName}
          </h4>
          <p className="text-sm font-normal text-gray-600">Supplier Name</p>
        </div>

      {/* Post followers */}
      <div className="mb-3 mt-6 flex gap-4 md:!gap-14">
        {/* <div className="flex flex-col items-center justify-center">
          <h4 className="text-2xl font-bold text-navy-700 dark:text-white">
             {customer}
          </h4>
          <p className="text-sm font-normal text-gray-600">Customer Name</p>
        </div> */}
        <div className="flex flex-col items-center justify-center">
          <h4 className="text-2xl font-bold text-navy-700 dark:text-white">
            {data?.supplierStatus ? 'Active Account' : 'Non Active Account'  }
          </h4>
          <p className="text-sm font-normal text-gray-600">Current Status</p>
        </div>
      </div>
        <div className="flex flex-col w-full">
        
          <DropDown
            variant="auth"
            extra="mb-3"
            label="Supplier Status"
            placeholder={ data?.JobStatus ? data?.JobStatus : 'Select the current status' 
            }
            id="jobType"
            onChange={(selectedValue:string) => {onSubmit(selectedValue)}}
            value={(selectedValue)}
            options={JobTypes}
          />
        </div>
    </Card>
  );
};

export default Banner;
