
import CurrencyInput from 'react-currency-input-field';

// Custom components
function InputFieldCurrency(props: {
  id: string;
  label: string;
  extra?: string;
  placeholder: string;
  variant?: string;
  state?: string;
  disabled?: boolean;
  onValueChange?: any;
  onChange?: any;
  defaultValue?: any;
  decimalsLimit?: any;
  Prefix?: any;
  Name?: any;
  error?: any;
  errorMessage?: any;
}) {
  const {
    label,
    id,
    extra,
    placeholder,
    variant,
    state,
    disabled,
    onValueChange,
    defaultValue,
    decimalsLimit,
    Prefix,
    Name,
    error,
    errorMessage,
    onChange,
  } = props;

  return (
    <div className={`${extra}`}>
      <label
        htmlFor={id}
        className={`text-sm text-navy-700 dark:text-white ${
          variant === "auth" ? "ml-1.5 font-medium" : "ml-3 font-bold"
        }`}
      >
        {label}
      </label>
      <CurrencyInput
          id={id}
          name={Name}
          className={`mt-2 flex h-12 w-full hover:border-gray-400 focus:border-blue-500 focus:ring focus:ring-blue-500/20 items-center justify-center rounded-md border bg-white/0 p-3 text-sm outline-none ${
            disabled === true
              ? "!border-none !bg-gray-100 dark:!bg-white/5 dark:placeholder:!text-[rgba(255,255,255,0.15)]"
              : state === "error"
              ? "border-red-500 text-red-500 placeholder:text-red-500 dark:!border-red-400 dark:!text-red-400 dark:placeholder:!text-red-400"
              : state === "success"
              ? "border-green-500 text-green-500 placeholder:text-green-500 dark:!border-green-400 dark:!text-green-400 dark:placeholder:!text-green-400"
              : "border-gray-200 dark:!border-white/10 dark:text-white"
          }`}
          placeholder={placeholder}
          prefix={Prefix}
          defaultValue={defaultValue}
          decimalsLimit={decimalsLimit}
          onValueChange={onValueChange}
          disabled={disabled}
          onChange={onChange}
          
          />
      {error && (
          <span className="text-red-500 text-sm">
            {(errorMessage) as string}
          </span>
      )}
    </div>
  );
}

export default InputFieldCurrency;
