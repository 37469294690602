import ButtonField from "components/fields/ButtonField";
import { MdDelete } from "react-icons/md";
import { AiFillFileAdd } from "react-icons/ai";
import { IoMdDownload } from "react-icons/io";


import Card from "components/card";

const Invoice = () => {
  return (
    <Card extra={"w-full p-4 h-full"}>
      <div className="mb-8 w-full">
      <div className="grid grid-cols-3 grid-rows-1 gap-4">
          <div className="col-span-2">
          <p className="text-xl font-bold text-navy-700 dark:text-white">
          Invoices
        </p>
        <p className="mt-2 text-base text-gray-600">
          You can view and add invoices to your job.
        </p>
          </div>
          <div className="relative flex items-end justify-end bottom-3">
            <ButtonField
              Name={<AiFillFileAdd />}
              type="button"
              color="clear"
              id="addInvoice"
              extra={"sm:w-[calc(100%-17rem)] md:w-[calc(100%-12rem)] lg:w-[calc(100%-17rem)]"}
              isLoading={false}
              onClick={() => {console.log("Add Invoice")}}
              />
          </div>
      </div>

      {/* Project 1 */}
      </div>
        <div className="h-full overflow-auto flex flex-col items-start justify-center rounded-md border-[1px] border-gray-200 bg-white bg-clip-border px-3 py-4 dark:border-white/20 dark:!bg-navy-700 ">
            <div className="w-full h-full">
              <div className="flex flex-col gap-4 w-full h-full">
            {/* Cards */}
            <div className="flex flex-col gap-2">
                <div className="grid grid-cols-3 grid-rows-1 gap-2 w-full">
                    <div className="col-span-2">
                    <h4 className="text-md font-semibold text-navy-700 dark:text-white text-wrap">
                      Invoice Name
                    </h4>
                    <p className="text-sm text-gray-600 dark:text-gray-400">
                      Description
                    </p>
                    <p className="text-sm text-gray-600 dark:text-gray-400">
                      Date Added to Job
                    </p>
                    </div>
                    <div className=" relative flex justify-end items-end bottom-3">
                        <ButtonField
                        Name={<IoMdDownload />}
                        type="button"
                        color="clear"
                        id="viewSupplier"
                        extra={"sm:w-[calc(100%-17rem)] md:w-[calc(100%-12rem)] lg:w-[calc(100%-17rem)] ml-1"}
                        isLoading={false}
                        onClick={() => {console.log("Download Invoice")}}
                        />
                        <ButtonField
                        Name={<MdDelete />}
                        type="button"
                        color="clear"
                        id="viewSupplier"
                        extra={"sm:w-[calc(100%-17rem)] md:w-[calc(100%-12rem)] lg:w-[calc(100%-17rem)] ml-1 bg-red-500 hover:bg-red-600 active:bg-red-700 dark:bg-red-400 dark:hover:bg-red-300 dark:active:bg-red-200"}
                        isLoading={false}
                        onClick={() => {console.log("Delete Invoice")}}
                        />
                    </div>
                </div>
            </div>
          </div>
          </div>
      </div>
    </Card>
  );
};

export default Invoice;
