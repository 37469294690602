import React, { useState } from 'react';
import Card from "components/card";

import { useForm, Controller } from 'react-hook-form';
import InputField from "components/fields/InputField";
import DropDown from 'components/fields/DropDownField';
import DatePicker from 'components/calendar/datePicker';
import Button from 'components/fields/ButtonField';
import  Country  from 'Schema/countries';
import InputFieldCurrency from 'components/fields/InputFieldCurrency';

import { zodResolver } from '@hookform/resolvers/zod';
import validationSchemas from 'Schema/validation';
import dayjs from 'dayjs';

const EditJob = (props:any) => {

  const { data, sendBackData} = props;

  const {control, handleSubmit, formState: { errors } } = useForm({
    resolver: zodResolver(validationSchemas.jobPage3)
  });

  const [showStart , setShowStart] = useState(false);
  const [showEnd , setShowEnd] = useState(false);


  const handleCloseStart = (state: boolean | ((prevState: boolean) => boolean)) => { setShowStart(state); }
  

  const handleCloseEnd = (state: boolean | ((prevState: boolean) => boolean)) => { setShowEnd(state); }

 const onSubmit = (data:any) => {
    sendBackData({ inputs: data });
 };

 const JobTypes = [
  { value: 'Loft Conversion', label: 'Loft Conversion' },
  { value: 'One story Extension', label: 'One story Extension' },
  { value: 'Two story Extension', label: 'Two story Extension' },
  { value: 'Demolition + Rebuild', label: 'Demolition + Rebuild' },
  { value: 'New Home', label: 'New Home' },
  { value: 'Plumbing', label: 'Plumbing' },
  { value: 'Electrical work', label: 'Electrical work' },
  { value: 'Painting', label: 'Painting' },
];


  return (
    <Card extra={"w-full p-4 h-full"}>
      <div className="mb-2 w-full">
        <p className="text-xl font-bold text-navy-700 dark:text-white">
          Edit Job
        </p>
        <p className="mt-2 text-base text-gray-600">
          Edit the current job details.
        </p>
      </div>
      {/* Job Info */}
          {/* content */}
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="">
      <Controller
            name="jobName"
            control={control}
            render={({ field }) => (
              <InputField
                variant=""
                extra="mb-3"
                label="Job Name"
                placeholder={ data?.JobName ? data?.JobName : 'Enter a name for this job'
                }
                id="jobName"
                onChange={field.onChange}
                value={field.value}
                error={errors.jobName}
                errorMessage={errors.jobName?.message}
              />
            )}
          />
      </div>
      <div className="grid grid-cols-1 gap-3 md:grid-cols-2">
          <Controller
            name="jobType"
            control={control}
            render={({ field }) => (
              <DropDown
                variant=""
                extra="mb-3"
                label="Job Type"
                placeholder={ data?.JobType ? data.JobType :
                  'Select what type of job this is' 
                }
                id="jobType"
                onChange={field.onChange}
                value={field.value}
                error={errors.jobType}
                errorMessage={errors.jobType?.message}
                options={JobTypes}
              />
            )}
          />



        <Controller
            name="EstimatedCost"
            control={control}
            render={({ field }) => (
            <InputFieldCurrency
              label="Estimated Cost"
              placeholder={data?.JobEstimate ? data?.JobEstimate : 'Enter the estimated cost of this job'}
              id="EstimatedCost"
              Name="EstimatedCost"
              onValueChange={() => field.value}
              onChange={field.onChange}
              decimalsLimit={2}
              Prefix="£"
              variant=""
              extra="mb-3"
              error={errors.EstimatedCost}
              errorMessage={errors.EstimatedCost?.message}
            />
          )}
          />
        <Controller
            name="startDate"
            control={control}
            render={({ field }) => (
              <DatePicker
                variant=""
                label="Estimated Start Date"
                placeholder={ data?.JobStartDate ? dayjs(data?.JobStartDate).format('ddd MMM YYYY') : 
                  'Select the estimated start date for this job' 
                }
                id="startDate"
                onChange={field.onChange}
                value={field.value}
                error={errors.startDate}
                errorMessage={errors.startDate?.message}
                show={showStart}
                setShow={handleCloseStart}

              />
            )}
          />
        <Controller
            name="endDate"
            control={control}
            render={({ field }) => (
              <DatePicker
              variant=""
              label="Estimated End Date"
              placeholder={
                data?.JobEndDate ? dayjs(data?.JobEndDate).format('ddd MMM YYYY') : 
                'Select the estimated end date for this job'
              }
              id="startDate"
              onChange={field.onChange}
              value={field.value}
              error={errors.endDate}
              errorMessage={errors.endDate?.message}
              show={showEnd}
              setShow={handleCloseEnd}
              />
            )}  
          />
      </div>
      <div className="mt-4">
        <Controller
          name="address1"
          control={control}
          render={({ field }) => (
            <InputField
              extra="mb-3"
              label="Address Line 1"
              placeholder={data?.JobAddress1 ? data?.JobAddress1 : 'eg. Main Street 203'}
              id="address1"
              type="text"
              onChange={field.onChange}
              value={field.value}
              error={errors.address1}
              errorMessage={errors.address1?.message}
            />
          )}
        />
        <Controller
          name="address2"
          control={control}
          render={({ field }) => (
            <InputField
              extra="mb-3"
              label="Address Line 2"
              placeholder={data?.JobAddress2 ? data?.JobAddress2 : 'eg. Apartment, Floor'}
              id="address2"
              type="text"
              onChange={field.onChange}
              value={field.value}
              error={errors.address2}
              errorMessage={errors.address2?.message}
            />
          )}
        />

        {/* inputs */}
        <div className="grid grid-cols-2 gap-3">
        <Controller
              name="country"
              control={control}
              render={({ field }) => (
                <DropDown
                  variant=""
                  extra="mb-3"
                  label="Country"
                  placeholder={data?.JobCountry ? data?.JobCountry.label : 'eg. United Kingdom'}
                  id="country"
                  onChange={field.onChange}
                  isSearchable={true}
                  value={field.value}
                  error={errors.country}
                  errorMessage={errors.country?.message}
                  options={Country}
                />
              )}
            />
         

          <div className="col-span-1 grid grid-cols-2 gap-3">
          <Controller
            name="city"
            control={control}
            render={({ field }) => (
              <InputField
                label="City"
                placeholder={data?.JobCity ? data?.JobCity : 'eg. London'}
                id="city"
                type="text"
                onChange={field.onChange}
                value={field.value}
                error={errors.city}
                errorMessage={errors.city?.message}
              />
            )}
          />
        <Controller
              name="postcode"
              control={control}
              render={({ field }) => (
                <InputField
                  label={data?.JobPostCode ? data?.JobPostCode : 'Post Code'}
                  placeholder="OX4 2JY"
                  id="postcode"
                  type="text"
                  onChange={field.onChange}
                  value={field.value}
                  error={errors.postcode}
                  errorMessage={errors.postcode?.message}
                />
              )}
            />

          </div>
        </div>
      </div>
      <div className='pl-[55%]'>
      <Controller
          name="UpdateButton"
          control={control}
          render={() => (
            <Button
              color="clear"
              type="submit"
              Name="Update"
              className="w-[100px] h-[40px] text-sm"
            />
          )}
      />
    </div>
    </form>
    </Card>
  );
};

export default EditJob;
