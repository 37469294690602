import Card from "components/card";

import { useForm, Controller } from 'react-hook-form';
import InputField from "components/fields/InputField";
import DropDown from 'components/fields/DropDownField';
import PhoneInput from 'components/fields/PhoneInput';
import Button from 'components/fields/ButtonField';
import  Country  from 'Schema/countries';

import { zodResolver } from '@hookform/resolvers/zod';
import validationSchemas from 'Schema/validation';

const EditJob = (props:any) => {

  const { data, sendBackData} = props;

  const {control, handleSubmit, formState: { errors } } = useForm({
    resolver: zodResolver(validationSchemas.Supplier3)
  });

 const onSubmit = (data:any) => {
    sendBackData({ inputs: data });
 };

 const JobTypes = [
  { value: 'General', label: 'General' },
  { value: 'Electrical', label: 'Electrical' },
  { value: 'Plumbing', label: 'Plumbing' },
  { value: 'Flooring', label: 'Flooring' },
  { value: 'Timber', label: 'Timber' },
  { value: 'Roofing', label: 'Roofing' },
  { value: 'Painting & Decorating', label: 'Painting & Decorating' },
  { value: 'Landscaping', label: 'Landscaping' },
  { value: 'Gardening', label: 'Gardening' },
  { value: 'Cleaning', label: 'Cleaning' },
  { value: 'Fencing', label: 'Fencing' },
  { value: 'Windows', label: 'Windows' },
  { value: 'Doors', label: 'Doors' },
  { value: 'Gutters', label: 'Gutters' },
  { value: 'Scaffolding', label: 'Scaffolding' },
  { value: 'Misc', label: 'Misc' },
];


  return (
    <Card extra={"w-full p-4"}>
      <div className="mb-2 w-full">
        <p className="text-xl font-bold text-navy-700 dark:text-white">
          Edit Job
        </p>
        <p className="mt-2 text-base text-gray-600">
          Edit the current job details.
        </p>
      </div>
      {/* Job Info */}
          {/* content */}
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="">
      </div>
      <div className="grid grid-cols-1 gap-3 md:grid-cols-2">
      <Controller
            name="supplierName"
            control={control}
            render={({ field }) => (
              <InputField
                variant=""
                extra="mb-3"
                label="Supplier Name"
                placeholder={ data?.supplierName ? data?.supplierName : 'No Name Provided'
                }
                id="supplierName"
                onChange={field.onChange}
                value={field.value}
                error={errors.supplierName}
                errorMessage={errors.supplierName?.message}
              />
            )}
          />
          
          <Controller
            name="supplierCategory"
            control={control}
            render={({ field }) => (
              <DropDown
                variant=""
                extra="mb-3"
                isSearchable={true}
                label="Supplier Category"
                placeholder={ data?.supplierCategory ? data.supplierCategory :
                  'Select what type of job this is' 
                }
                id="supplierCategory"
                onChange={field.onChange}
                value={field.value}
                error={errors.supplierCategory}
                errorMessage={errors.supplierCategory?.message}
                options={JobTypes}
              />
            )}
          />



          <Controller
            name="supplierEmail"
            control={control}
            render={({ field }) => (
              <InputField
                variant=""
                extra="mb-3"
                label="Supplier Email"
                placeholder={ data?.supplierEmail ? data?.supplierEmail : 'No Email Provided'
                }
                id="supplierName"
                onChange={field.onChange}
                value={field.value}
                error={errors.supplierEmail}
                errorMessage={errors.supplierEmail?.message}
              />
            )}
          />

          <Controller
            name="supplierPhone"
            control={control}
            render={({ field }) => (
              <PhoneInput
                variant=""
                country="GB"
                extra="mb-3"
                label="Contact Number"
                placeholder={ data?.supplierPhone ? data?.supplierPhone : 'No Phone Provided'}
                id="supplierPhone"
                autoComplete="off"
                onChange={(value: number) => { field.onChange(value); }}
                value={field.value}
                error={errors.supplierPhone}
                errorMessage={errors.supplierPhone?.message}
              />
            )}
          />


        
      </div>
      <div className="mt-4">
        <Controller
          name="AddressLineOne"
          control={control}
          render={({ field }) => (
            <InputField
              extra="mb-3"
              label="Address Line 1"
              placeholder={data?.supplierAddress1 ? data?.supplierAddress1 : 'eg. Main Street 203'}
              id="AddressLineOne"
              type="text"
              onChange={field.onChange}
              value={field.value}
              error={errors.AddressLineOne}
              errorMessage={errors.AddressLineOne?.message}
            />
          )}
        />
        <Controller
          name="address2"
          control={control}
          render={({ field }) => (
            <InputField
              extra="mb-3"
              label="Address Line 2"
              placeholder={data?.supplierAddress2 ? data?.supplierAddress2 : 'eg. Apartment, Floor'}
              id="address2"
              type="text"
              onChange={field.onChange}
              value={field.value}
              error={errors.address2}
              errorMessage={errors.address2?.message}
            />
          )}
        />

        {/* inputs */}
        <div className="grid grid-cols-2 gap-3">
        <Controller
              name="country"
              control={control}
              render={({ field }) => (
                <DropDown
                  variant=""
                  extra="mb-3"
                  label="Country"
                  placeholder={data?.supplierCountry ? data?.supplierCountry.label : 'eg. United Kingdom'}
                  id="country"
                  onChange={field.onChange}
                  isSearchable={true}
                  value={field.value}
                  error={errors.country}
                  errorMessage={errors.country?.message}
                  options={Country}
                />
              )}
            />
         

          <div className="col-span-1 grid grid-cols-2 gap-3">
          <Controller
            name="City"
            control={control}
            render={({ field }) => (
              <InputField
                label="City"
                placeholder={data?.supplierCity ? data?.supplierCity : 'eg. London'}
                id="City"
                type="text"
                onChange={field.onChange}
                value={field.value}
                error={errors.City}
                errorMessage={errors.City?.message}
              />
            )}
          />
        <Controller
              name="Postcode"
              control={control}
              render={({ field }) => (
                <InputField
                  label={data?.SupplierPostalCode ? data?.SupplierPostalCode : 'Post Code'}
                  placeholder="OX4 2JY"
                  id="Postcode"
                  type="text"
                  onChange={field.onChange}
                  value={field.value}
                  error={errors.Postcode}
                  errorMessage={errors.Postcode?.message}
                />
              )}
            />

          </div>
        </div>
      </div>
      <div className='pl-[55%]'>
      <Controller
          name="UpdateButton"
          control={control}
          render={() => (
            <Button
              color="clear"
              type="submit"
              Name="Update"
              className="w-[100px] h-[40px] text-sm"
            />
          )}
      />
    </div>
    </form>
    </Card>
  );
};

export default EditJob;
