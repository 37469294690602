import { z } from "zod";

const Password: z.ZodSchema = z
  .object({   
    OldPassword: z.string().min(1, { message: 'Field required' }),
    NewPassword: z.string().min(8, { message: "Password must be 8 characters long" }).regex(/^(?=.*[A-Z])(?=.*[\W])(?=.*[0-9])(?=.*[a-z]).{8,128}$/, {message: 'Password must contain at least one number and one special character'}),
    NewPasswordConfirm: z.string()
      .min(1, { message: "Filed required" }),
  }) 
  .refine((data) => data.NewPassword === data.NewPasswordConfirm, {
    path: ["confirmPassword"],
    message: "Password don't match",
  });

const Address: z.ZodSchema = z.object({
    address1: z.string().min(1, { message: 'Required: Enter the first line of the address' }),
    address2: z.string().optional(),
    city: z.string().min(1, { message: 'Required: Enter the city' }),
    county: z.object({
        label: z.string(),
        value: z.string().min(1, { message: 'Required: Select the county.' }),
      }),
    country: z.object({
        label: z.string(),
        value: z.string().min(1, { message: 'Required: Select the country.' }),
      }),
    postcode: z.string().min(1, { message: 'Required: Enter the postcode' })
      .regex(/^[a-zA-Z]{1,2}\d[a-zA-Z\d]?\s*\d[a-zA-Z]{2}$/, { message: 'Invalid postcode' })
});

const AddressOptional: z.ZodSchema = z.object({
    address1: z.string().optional(),
    address2: z.string().optional(),
    city: z.string().optional(),
    county: z.object({
        label: z.string().optional(),
        value: z.string().optional(),
    }),
    country: z.object({
        label: z.string().optional(),
        value: z.string().optional(),
    }),
    postcode: z.string().regex(/^[a-zA-Z]{1,2}\d[a-zA-Z\d]?\s*\d[a-zA-Z]{2}$/, { message: 'Invalid postcode' }).optional()
});


const UserUpdate: z.ZodSchema = z.object({
  email: z.string().email({ message: 'Invalid email' }).optional(),
  FirstName: z.string().optional(),
  LastName: z.string().optional(),
  MiddleName: z.string().optional(),
  ContactNumber: z.string().regex(/^(((\+44\s?\d{4}|\(?0\d{4}\)?)\s?\d{3}\s?\d{3})|((\+44\s?\d{3}|\(?0\d{3}\)?)\s?\d{3}\s?\d{4})|((\+44\s?\d{2}|\(?0\d{2}\)?)\s?\d{4}\s?\d{4}))(\s?\#(\d{4}|\d{3}))?$/, {message: "Invalid phone number"}).optional(),
});

const Email: z.ZodSchema = z.object({
  Email: z.string().email({ message: 'Please enter your email only' }),
});

const login: z.ZodSchema = z.object({
  username: z.string().min(1, { message: 'Field required' }),
  password: z.string().min(1, { message: 'Field required' }),
});

const customer: z.ZodSchema = z.object({
  customerEmail:  z.string().email({ message: 'Invalid email' }),
  customerName:   z.string().min (1, { message: 'Required' }),
  AddressLineOne: z.string().min(1, { message: 'Required' }),
  customerAddress2: z.string().optional(),
  customerPhone: z.string().optional(),
  City: z.string().min(1, { message: 'Required' }),
  County: z.string().min(1, { message: 'Required' }),
  Postcode: z.string().min(1, { message: 'Required' })
    .regex(/^[a-zA-Z]{1,2}\d[a-zA-Z\d]?\s*\d[a-zA-Z]{2}$/, { message: 'Invalid postcode' })
});


const Supplier1: z.ZodSchema = z.object({
  supplierName: z.string().min (1, { message: 'Required' }),
  supplierCategory: z.object({
    label: z.string(),
    value: z.string(),
  }),
  supplierPhone: z.string().optional(),
  supplierEmail: z.string().email({ message: 'Invalid email' }).optional(),
});

// const Supplier1: z.ZodSchema = z.object({
//   supplierName: z.string().min (1, { message: 'Required' }),
//   supplierCategory: z.array(
//     z.object({
//       label: z.string(),
//       value: z.string(),
//     })
//   ),
//   supplierPhone: z.string().optional(),
//   supplierEmail: z.string().email({ message: 'Invalid email' }).optional(),
// });

const Supplier2: z.ZodSchema = z.object({
  AddressLineOne: z.string().min(1, { message: 'Required' }),
  address2: z.string().optional(),
  City: z.string().min(1, { message: 'Required' }),
  County: z.string().min(1, { message: 'Required' }),
  country: z.object({
    label: z.string().optional(),
    value: z.string().optional(),
}),
  Postcode: z.string().min(1, { message: 'Required' })
    .regex(/^[a-zA-Z]{1,2}\d[a-zA-Z\d]?\s*\d[a-zA-Z]{2}$/, { message: 'Invalid postcode' })
});

const Supplier3: z.ZodSchema = z.object({
  supplierName: z.string().min (1, { message: 'Required' }).optional(),
  supplierCategory: z.object({
    label: z.string(),
    value: z.string(),
  }).optional(),
  supplierPhone: z.string().optional(),
  supplierEmail: z.string().email({ message: 'Invalid email' }).optional(),
  AddressLineOne: z.string().min(1, { message: 'Required' }).optional(),
  address2: z.string().optional(),
  City: z.string().min(1, { message: 'Required' }).optional(),
  County: z.string().min(1, { message: 'Required' }).optional(),
  country: z.object({
    label: z.string().optional(),
    value: z.string().optional(),
}).optional(),
  Postcode: z.string().min(1, { message: 'Required' })
    .regex(/^[a-zA-Z]{1,2}\d[a-zA-Z\d]?\s*\d[a-zA-Z]{2}$/, { message: 'Invalid postcode' }).optional()
});


const JobPage1: z.ZodSchema = z.object({
  jobName: z.string().min (1, { message: 'Job Name Required' }),
  jobCustomer: z.object({
    label: z.string(),
    value: z.string(),
  }),
  jobType: z.object({
      label: z.string(),
      value: z.string(),
  }),
  EstimatedCost: z.string().min (1, {message: 'Estimated cost is required'}), 
  startDate: z.date().min(new Date(2000, 0, 1), {message: 'Start date should be after January 1, 2000'}),
  endDate: z.date().min(new Date(2000, 0, 1), {message: 'End date should be after January 1, 2000'}).optional(),
});


const JobPage2: z.ZodSchema = z.object({
  address1: z.string().min(1, { message: 'Required' }),
  address2: z.string().optional(),
  city: z.string().min(1, { message: 'Required' }),
  country: z.object({
    label: z.string().optional(),
    value: z.string().optional(),
}),
  postcode: z.string().min(1, { message: 'Required' })
    .regex(/^[a-zA-Z]{1,2}\d[a-zA-Z\d]?\s*\d[a-zA-Z]{2}$/, { message: 'Invalid postcode' })
});

const jobPage3: z.ZodSchema = z.object({
  jobName: z.string().min (1, { message: 'Job Name Required' }).optional(),
  jobType: z.object({
      label: z.string().optional(),
      value: z.string().optional(),
  }).optional(),
  EstimatedCost: z.string().min (1, {message: 'Estimated cost is required'}).optional(), 
  startDate: z.date().min(new Date(2000, 0, 1), {message: 'Start date should be after January 1, 2000'}).optional(),
  endDate: z.date().min(new Date(2000, 0, 1), {message: 'End date should be after January 1, 2000'}).optional(),
  address1: z.string().min(1, { message: 'Required' }).optional(),
  address2: z.string().optional(),
  city: z.string().min(1, { message: 'Required' }).optional(),
  country: z.object({
    label: z.string().optional(),
    value: z.string().optional(),
}).optional(),
  postcode: z.string().min(1, { message: 'Required' })
    .regex(/^[a-zA-Z]{1,2}\d[a-zA-Z\d]?\s*\d[a-zA-Z]{2}$/, { message: 'Invalid postcode' }).optional()
});

const Customerpage1: z.ZodSchema = z.object({
  firstName: z.string().min (1, { message: 'Required' }),
  lastName: z.string().min (1, { message: 'Required' }),
  email: z.string().email({ message: 'Invalid email' }),
  contactNumber: z.string().regex(/^(((\+44\s?\d{4}|\(?0\d{4}\)?)\s?\d{3}\s?\d{3})|((\+44\s?\d{3}|\(?0\d{3}\)?)\s?\d{3}\s?\d{4})|((\+44\s?\d{2}|\(?0\d{2}\)?)\s?\d{4}\s?\d{4}))(\s?\#(\d{4}|\d{3}))?$/, {message: "Invalid phone number"}),
});

const Customerpage2: z.ZodSchema = z.object({
  address1: z.string().min(1, { message: 'Required' }),
  address2: z.string().optional(),
  city: z.string().min(1, { message: 'Required' }),
  country: z.object({
    label: z.string().optional(),
    value: z.string().optional(),
}),
  postcode: z.string().min(1, { message: 'Required' })
    .regex(/^[a-zA-Z]{1,2}\d[a-zA-Z\d]?\s*\d[a-zA-Z]{2}$/, { message: 'Invalid postcode' })
});

const Customerpage3: z.ZodSchema = z.object({
  firstName: z.string().min (1, { message: 'Required' }).optional(),
  lastName: z.string().min (1, { message: 'Required' }).optional(),
  email: z.string().email({ message: 'Invalid email' }).optional(),
  contactNumber: z.string().regex(/^(((\+44\s?\d{4}|\(?0\d{4}\)?)\s?\d{3}\s?\d{3})|((\+44\s?\d{3}|\(?0\d{3}\)?)\s?\d{3}\s?\d{4})|((\+44\s?\d{2}|\(?0\d{2}\)?)\s?\d{4}\s?\d{4}))(\s?\#(\d{4}|\d{3}))?$/, {message: "Invalid phone number"}).optional(),
  address1: z.string().min(1, { message: 'Required' }).optional(),
  address2: z.string().optional(),
  city: z.string().min(1, { message: 'Required' }).optional(),
  country: z.object({
    label: z.string().optional(),
    value: z.string().optional(),
}).optional(),
  postcode: z.string().min(1, { message: 'Required' })
    .regex(/^[a-zA-Z]{1,2}\d[a-zA-Z\d]?\s*\d[a-zA-Z]{2}$/, { message: 'Invalid postcode' }).optional(),
  notes: z.string().optional()
});

const validationSchemas = {
    Password,
    Address,
    AddressOptional,
    UserUpdate,
    Email,
    login,
    Customerpage1,
    Customerpage2,
    Customerpage3,
    Supplier1,
    Supplier2,
    Supplier3,
    JobPage1,
    JobPage2,
    jobPage3,
};

export default validationSchemas;