import profile from "assets/img/crm/vbz.png";
import Avatar from 'react-avatar';
import banner from "assets/img/profile/banner.png";
import Card from "components/card";


const Banner = (bannerData:any) => {
  return (
    <Card extra={"items-center pt-[16px] pb-10 px-[16px] bg-cover"}>
      {/* background and profile */}
      <div
        className="jsu relative mt-1 flex h-28 w-full justify-center rounded-md bg-cover"
        style={{ backgroundImage: `url(${banner})` }}
      >
        <div className="absolute -bottom-12 flex h-[87px] w-[87px] items-center justify-center rounded-full bg-pink-400">
        <Avatar 
            src={ bannerData.bannerData.profilePic ? bannerData.bannerData.profilePic : ""}
            name={bannerData.bannerData.user ? bannerData.bannerData.user : "No Account Name"} 
            size="86" 
            round={true}
            textSizeRatio={2.5}
            className="flex cursor-pointer font-semibold text-gray-600 dark:text-white items-center justify-center text-center"
            />
        </div>
      </div>
      {/* name and position */}
      <div className="mt-14 flex flex-col items-center">
        <h4 className="mt-1 text-xl font-bold text-navy-700 dark:text-white">
        {bannerData.bannerData.firstName + " " + bannerData.bannerData.lastName}
        </h4>
        <div className="flex items-center justify-center">
        </div>
      </div>
    </Card>
  );
};

export default Banner;
